import React from 'react';
import { Typography, Box, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const darkTheme = {
  backgroundColor: '#1A1D1F',
  textColor: 'white',
  borderColor: '#F6983D',
};

export const StatCard = ({ title, value }) => (
  <Card sx={{ 
    backgroundColor: darkTheme.backgroundColor, 
    color: darkTheme.textColor, 
    border: `1px solid ${darkTheme.borderColor}`,
    borderRadius: '24px',
    height: '100%',
    background: 'linear-gradient(90deg, #291919 0%, black 100%)'
  }}>
    <CardContent>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      <Typography variant="h4">{value}</Typography>
    </CardContent>
  </Card>
);

const COLORS = {
  license: ['#FF7E00', '#A9A9A9'],
  assets: ['#FF7E00', '#A9A9A9'],
  category: ['#15849C', '#9C1515', '#D69A00', '#4ECDC4', '#C06C84'],
  status: ['#289C15', '#9C4E15', '#FF6B6B', '#4ECDC4', '#C06C84'],
};

const CustomLegend = ({ payload }) => (
  <Box display="flex" justifyContent="center" mt={2} flexWrap="wrap">
    {payload.map((entry, index) => (
      <Box key={`item-${index}`} display="flex" alignItems="center" mr={2} mb={1}>
        <Box
          width={10}
          height={10}
          borderRadius="50%"
          bgcolor={entry.color}
          mr={1}
        />
        <Typography variant="caption" color="white">
          {entry.value}
        </Typography>
      </Box>
    ))}
  </Box>
);

export const CategoryPieChart = ({ data, title, type }) => {
  let chartData, total;

  if (type === 'license' || type === 'assets') {
    total = data.used + data.available;
    chartData = [
      { name: type === 'license' ? 'Used' : 'Active', value: data.used },
      { name: type === 'license' ? 'Available' : 'Inactive', value: data.available }
    ];
  } else {
    chartData = data;
    total = data.reduce((sum, entry) => sum + entry.value, 0);
  }

  const displayData = total === 0 ? [{ name: 'No Data', value: 1 }] : chartData;

  return (
    <Card sx={{ 
      backgroundColor: darkTheme.backgroundColor, 
      color: darkTheme.textColor, 
      border: `1px solid ${darkTheme.borderColor}`,
      borderRadius: '12px',
      height: '100%'
    }}>
      <CardContent>
        <Typography variant="h6" align="center" fontWeight="bold" mb={2}>{title}</Typography>
        <ResponsiveContainer width="100%" height={150}>
          <PieChart>
            <Pie
              data={displayData}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius="75%"
              outerRadius="100%"
              paddingAngle={0}
              stroke="none"
            >
              {displayData.map((entry, index) => (
                <Cell 
                  key={`cell-${index}`} 
                  fill={total === 0 ? '#808080' : COLORS[type][index % COLORS[type].length]} 
                />
              ))}
            </Pie>
            <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
              <tspan x="50%" dy="-0.0em" fontSize="30" fontWeight="bold" fill="white" alignmentBaseline="middle" textAnchor="middle">
                {total}
              </tspan>
            </text>
          </PieChart>
        </ResponsiveContainer>
        <CustomLegend payload={chartData.map((entry, index) => ({
          value: `${entry.name}: ${entry.value}`,
          color: COLORS[type][index % COLORS[type].length]
        }))} />
      </CardContent>
    </Card>
  );
};

export const AssetLocationChart = ({ data }) => (
  <Card sx={{ 
    backgroundColor: darkTheme.backgroundColor, 
    color: darkTheme.textColor, 
    border: `1px solid ${darkTheme.borderColor}`,
    borderRadius: '24px',
    height: '100%'
  }}>
    <CardContent>
      <Typography variant="h6" align="center" fontWeight="bold" mb={2}>Top 5 Asset Location</Typography>
      <ResponsiveContainer width="100%" height={230}>
        <BarChart data={data} layout="horizontal">
          <XAxis dataKey="name" type="category" />
          <YAxis type="number" />
          <Tooltip />
          <Legend />
          <Bar dataKey="Laptop" stackId="a" fill="#15849C" />
        </BarChart>
      </ResponsiveContainer>
    </CardContent>
  </Card>
);

export const ListCard = ({ title, data, columns }) => (
  <Card sx={{ 
    backgroundColor: darkTheme.backgroundColor, 
    color: darkTheme.textColor, 
    border: `1px solid ${darkTheme.borderColor}`,
    borderRadius: '24px',
    height: '100%'
  }}>
    <CardContent>
        <Typography variant="h6" align="center" fontWeight="bold" mb={2}>{title}</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index} sx={{ color: 'white', borderBottom: '2px solid #F6983D' }}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={index}>
                <TableCell sx={{ color: 'white', borderBottom: 'none' }}>{item.name}</TableCell>
                <TableCell align="center" sx={{ color: 'white', borderBottom: 'none' }}>{item.value}</TableCell>
              </TableRow> 
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardContent>
  </Card>
);