export const settingsData = {
    license: {
      name: "FGV Asset Management POC",
      type: "Trial License",
      startDate: "01 July, 2024",
      endDate: "30 October, 2024",
      uploadDate: "01 July, 2024",
      totalLicense: 100,
      status: "Active"
    },
    users: [
      { name: "Admin1", email: "admin1@gmail.com", role: "Administrator", status: "Active" }
    ],
    totalUsers: 1,
    totalPages: 1
  };