export const laptopDetailData = {
  "FGV5340005754": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVGTSM00792100DA47200",
      assetNumber: "FGV5340005754",
      hostname: "FGV5340005754",
      ipAddress: "10.112.165.69",
      macAddress: "48-89-E7-B2-10-97",
      publicIpAddress: "211.25.233.84",
      lastSeen: "10-09-24 9:59",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 140.64, available: 159.36 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 381.0, available: 319.0 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "10-09-24 9:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "08-09-24 22:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "10-09-24 9:59", activity: "Last seen" },
      { date: "08-09-24 22:59", activity: "Location update" }
    ]
  },
  "FGV5340012606": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXB0WSM00A420150E17600",
      assetNumber: "FGV5340012606",
      hostname: "FGV5340012606",
      ipAddress: "10.112.164.71",
      macAddress: "D4-F3-2D-1B-8E-06",
      publicIpAddress: "211.25.233.89",
      lastSeen: "09-09-24 8:59",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 170.43, available: 129.57 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 340.08, available: 359.92 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "09-09-24 8:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "08-09-24 18:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "07-09-24 20:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 11:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "09-09-24 8:59", activity: "Last seen" },
      { date: "08-09-24 18:59", activity: "Location update" },
      { date: "07-09-24 20:59", activity: "Location update" },
      { date: "06-09-24 11:00", activity: "Location update" }
    ]
  },
  "PC5340010084": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVPMSM02A216081EB7600",
      assetNumber: "PC5340010084",
      hostname: "PC5340010084",
      ipAddress: "10.112.154.37",
      macAddress: "C8-CB-9E-47-28-A9",
      publicIpAddress: "211.25.233.83",
      lastSeen: "10-09-24 8:59",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 197.56, available: 102.44 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 330.41, available: 369.59 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "10-09-24 8:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "10-09-24 8:59", activity: "Last seen" }
    ]
  },
  "FLD5210235806": {
    organization: "FGV",
    company: "",
    branch: "Sabah",
    assetDetails: {
      type: "Laptop",
      serialNumber: "DTVWNSM00V24103CBE9600",
      assetNumber: "FLD5210235806",
      hostname: "FLD5210235806",
      ipAddress: "172.30.86.117",
      macAddress: "88-AE-DD-29-BB-97",
      publicIpAddress: "115.164.44.126",
      lastSeen: "11-09-24 6:59",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22000",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 150.82, available: 149.18 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 278.22, available: 421.78 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 5.072,
      longitude: 119.049
    },
    assetHistory: [
      { dateTime: "11-09-24 6:59", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" },
      { dateTime: "10-09-24 11:58", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" }
    ],
    activityLogs: [
      { date: "11-09-24 6:59", activity: "Last seen" },
      { date: "10-09-24 11:58", activity: "Location update" }
    ]
  },
  "FGV5340012607": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXB0WSM00A420150F27600",
      assetNumber: "FGV5340012607",
      hostname: "FGV5340012607",
      ipAddress: "10.112.164.210",
      macAddress: "D4-F3-2D-1B-8D-F7",
      publicIpAddress: "211.25.233.86",
      lastSeen: "11-09-24 15:08",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 149.13, available: 150.87 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 472.7, available: 227.3 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 15:08", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "10-09-24 8:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 15:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 15:08", activity: "Last seen" },
      { date: "10-09-24 8:59", activity: "Location update" },
      { date: "09-09-24 15:59", activity: "Location update" }
    ]
  },
  "PC5340004937F": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVCKSM01272310C583400",
      assetNumber: "PC5340004937F",
      hostname: "PC5340004937F",
      ipAddress: "10.112.153.213",
      macAddress: "E4-42-A6-2A-17-0A",
      publicIpAddress: "211.25.233.94",
      lastSeen: "11-09-24 9:02",
      status: "Active",
      remarks: "No F at the end"
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 170.15, available: 129.85 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 445.94, available: 254.06 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 9:02", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "10-09-24 9:35", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 12:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 11:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 9:02", activity: "Last seen" },
      { date: "10-09-24 9:35", activity: "Location update" },
      { date: "09-09-24 12:59", activity: "Location update" },
      { date: "06-09-24 11:00", activity: "Location update" }
    ]
  },
  "LPC200819230": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVLFSM00A0270C0A17600",
      assetNumber: "LPC200819230",
      hostname: "LPC200819230",
      ipAddress: "172.20.10.6",
      macAddress: "34-7D-F6-58-AC-D2",
      publicIpAddress: "183.171.64.164",
      lastSeen: "11-09-24 11:00",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 183.33, available: 116.67 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 260.06, available: 439.94 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 11:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 9:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 11:00", activity: "Last seen" },
      { date: "09-09-24 9:59", activity: "Location update" }
    ]
  },
  "PC5340008967": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVPMSM02A201185D97600",
      assetNumber: "PC5340008967",
      hostname: "PC5340008967",
      ipAddress: "172.31.121.27",
      macAddress: "C4-BD-E5-9A-BB-63",
      publicIpAddress: "1.9.134.45",
      lastSeen: "12-09-24 0:00",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 141.77, available: 158.23 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 481.22, available: 218.78 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "12-09-24 0:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "11-09-24 23:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "10-09-24 9:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 7:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "12-09-24 0:00", activity: "Last seen" },
      { date: "11-09-24 23:00", activity: "Location update" },
      { date: "10-09-24 9:59", activity: "Location update" },
      { date: "09-09-24 7:59", activity: "Location update" }
    ]
  },
  "PC5340005898": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVGTSM007921057EC7200",
      assetNumber: "PC5340005898",
      hostname: "PC5340005898",
      ipAddress: "192.168.116.180",
      macAddress: "A0-51-0B-D3-9E-47",
      publicIpAddress: "42.153.143.37",
      lastSeen: "11-09-24 8:59",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 144.41, available: 155.59 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 386.52, available: 313.48 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 8:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "10-09-24 21:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 16:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 8:59", activity: "Last seen" },
      { date: "10-09-24 21:59", activity: "Location update" },
      { date: "09-09-24 16:59", activity: "Location update" }
    ]
  },
  "FGV5340012046": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVVESM00Y350046CC7600",
      assetNumber: "FGV5340012046",
      hostname: "FGV5340012046",
      ipAddress: "10.112.164.70",
      macAddress: "E0-C2-64-03-7D-84",
      publicIpAddress: "211.25.233.89",
      lastSeen: "11-09-24 9:00",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 120.36, available: 179.64 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 383.15, available: 316.85 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 9:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "10-09-24 8:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 11:02", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 9:00", activity: "Last seen" },
      { date: "10-09-24 8:59", activity: "Location update" },
      { date: "06-09-24 11:02", activity: "Location update" }
    ]
  },
  "FGV5340012047": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVVESM00Y350046AE7600",
      assetNumber: "FGV5340012047",
      hostname: "FGV5340012047",
      ipAddress: "192.168.237.185",
      macAddress: "E0-C2-64-02-F2-BF",
      publicIpAddress: "115.164.58.131",
      lastSeen: "07-09-24 8:58",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 137.92, available: 162.08 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 361.21, available: 338.79 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "07-09-24 8:58", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 11:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "07-09-24 8:58", activity: "Last seen" },
      { date: "06-09-24 11:00", activity: "Location update" }
    ]
  },
  "FGV5340011751": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVVESM00Y337197CB7600",
      assetNumber: "FGV5340011751",
      hostname: "FGV5340011751",
      ipAddress: "192.168.1.110",
      macAddress: "E0-2E-0B-AA-0D-D4",
      publicIpAddress: "124.13.78.222",
      lastSeen: "12-09-24 0:00",
      status: "Active",
      remarks: "Asset number is wrong -correct one is FGV5340011750"
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 165.61, available: 134.39 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 214.16, available: 485.84 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "12-09-24 0:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "11-09-24 8:23", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "10-09-24 17:01", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "12-09-24 0:00", activity: "Last seen" },
      { date: "11-09-24 8:23", activity: "Location update" },
      { date: "10-09-24 17:01", activity: "Location update" }
    ]
  },
  "PC5340008963": {
    organization: "FGV",
    company: "",
    branch: "Johor",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVPMSM02A201186917600",
      assetNumber: "PC5340008963",
      hostname: "PC5340008963",
      ipAddress: "192.168.0.213",
      macAddress: "3A-92-33-FC-59-AA",
      publicIpAddress: "60.49.51.231",
      lastSeen: "11-09-24 9:02",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 178.04, available: 121.96 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 296.03, available: 403.97 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 1.4703,
      longitude: 103.903
    },
    assetHistory: [
      { dateTime: "11-09-24 9:02", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "09-09-24 9:13", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "06-09-24 9:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "05-09-24 15:45", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "04-09-24 12:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" }
    ],
    activityLogs: [
      { date: "11-09-24 9:02", activity: "Last seen" },
      { date: "09-09-24 9:13", activity: "Location update" },
      { date: "06-09-24 9:59", activity: "Location update" },
      { date: "05-09-24 15:45", activity: "Location update" },
      { date: "04-09-24 12:59", activity: "Location update" }
    ]
  },
  "FGV5210236286": {
    organization: "FGV",
    company: "",
    branch: "Sabah",
    assetDetails: {
      type: "Laptop",
      serialNumber: "DTVWNSM00V3160046A9600",
      assetNumber: "FGV5210236286",
      hostname: "FGV5210236286",
      ipAddress: "169.254.47.223",
      macAddress: "88-AE-DD-2E-74-38",
      publicIpAddress: "60.48.210.253",
      lastSeen: "11-09-24 5:59",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22000",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 197.34, available: 102.66 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 361.71, available: 338.29 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 5.072,
      longitude: 119.049
    },
    assetHistory: [
      { dateTime: "11-09-24 5:59", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" },
      { dateTime: "10-09-24 18:58", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" }
    ],
    activityLogs: [
      { date: "11-09-24 5:59", activity: "Last seen" },
      { date: "10-09-24 18:58", activity: "Location update" }
    ]
  },
  "FGV5210237057": {
    organization: "FGV",
    company: "",
    branch: "Sabah",
    assetDetails: {
      type: "Laptop",
      serialNumber: "DTVWNSM01Y338025D79600",
      assetNumber: "FGV5210237057",
      hostname: "FGV5210237057",
      ipAddress: "192.168.30.235",
      macAddress: "E0-2E-0B-7F-80-00",
      publicIpAddress: "183.171.158.245",
      lastSeen: "11-09-24 9:59",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22000",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 134.98, available: 165.02 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 318.99, available: 381.01 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 5.072,
      longitude: 119.049
    },
    assetHistory: [
      { dateTime: "11-09-24 9:59", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" }
    ],
    activityLogs: [
      { date: "11-09-24 9:59", activity: "Last seen" }
    ]
  },
  "EtonComell": {
    organization: "FGV",
    company: "",
    branch: "Johor",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVPMSM02A201186637600",
      assetNumber: "EtonComell",
      hostname: "EtonComell",
      ipAddress: "192.168.0.84",
      macAddress: "C4-BD-E5-BF-C3-6D",
      publicIpAddress: "124.13.119.220",
      lastSeen: "10-09-24 9:16",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 169.74, available: 130.26 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 486.31, available: 213.69 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 1.4703,
      longitude: 103.903
    },
    assetHistory: [
      { dateTime: "10-09-24 9:16", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "09-09-24 8:58", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "06-09-24 11:55", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "05-09-24 9:33", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "04-09-24 14:04", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" }
    ],
    activityLogs: [
      { date: "10-09-24 9:16", activity: "Last seen" },
      { date: "09-09-24 8:58", activity: "Location update" },
      { date: "06-09-24 11:55", activity: "Location update" },
      { date: "05-09-24 9:33", activity: "Location update" },
      { date: "04-09-24 14:04", activity: "Location update" }
    ]
  },
  "FGV5210237050": {
    organization: "FGV",
    company: "",
    branch: "Sabah",
    assetDetails: {
      type: "Laptop",
      serialNumber: "DTVWNSM01Y3380254D9600",
      assetNumber: "FGV5210237050",
      hostname: "FGV5210237050",
      ipAddress: "192.168.35.182",
      macAddress: "E0-2E-0B-7D-E0-CE",
      publicIpAddress: "183.171.158.92",
      lastSeen: "11-09-24 8:06",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 187.64, available: 112.36 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 270.92, available: 429.08 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 5.072,
      longitude: 119.049
    },
    assetHistory: [
      { dateTime: "11-09-24 8:06", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" }
    ],
    activityLogs: [
      { date: "11-09-24 8:06", activity: "Last seen" }
    ]
  },
  "FLD5340011379": {
    organization: "FELDA",
    company: "FPM",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "UNKG3SM001334004",
      assetNumber: "FLD5340011379",
      hostname: "FLD5340011379",
      ipAddress: "172.20.10.2",
      macAddress: "74-97-79-79-5C-C7",
      publicIpAddress: "42.153.132.147",
      lastSeen: "11-09-24 9:00",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 166.34, available: 133.66 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 296.69, available: 403.31 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 9:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 13:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "07-09-24 23:42", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "05-09-24 16:34", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 9:00", activity: "Last seen" },
      { date: "09-09-24 13:59", activity: "Location update" },
      { date: "07-09-24 23:42", activity: "Location update" },
      { date: "05-09-24 16:34", activity: "Location update" }
    ]
  },
  "FGV5340004785": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVCKSM012746079A53400",
      assetNumber: "FGV5340004785",
      hostname: "FGV5340004785",
      ipAddress: "10.112.154.116",
      macAddress: "00-E1-8C-3E-F5-99",
      publicIpAddress: "211.25.233.84",
      lastSeen: "11-09-24 10:06",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 111.24, available: 188.76 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 498.97, available: 201.03 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 10:06", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "10-09-24 8:57", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 8:16", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 12:52", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 10:06", activity: "Last seen" },
      { date: "10-09-24 8:57", activity: "Location update" },
      { date: "09-09-24 8:16", activity: "Location update" },
      { date: "06-09-24 12:52", activity: "Location update" }
    ]
  },
  "PC5340009189": {
    organization: "FGV",
    company: "",
    branch: "Johor",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVPMSM02A20701ED07600",
      assetNumber: "PC5340009189",
      hostname: "PC5340009189",
      ipAddress: "192.168.0.130",
      macAddress: "20-C1-9B-77-0C-EA",
      publicIpAddress: "60.49.51.231",
      lastSeen: "11-09-24 9:07",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 118.79, available: 181.21 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 265.54, available: 434.46 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 1.4703,
      longitude: 103.903
    },
    assetHistory: [
      { dateTime: "11-09-24 9:07", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "10-09-24 9:06", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "09-09-24 9:06", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "06-09-24 10:06", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "05-09-24 9:06", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "04-09-24 13:06", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" }
    ],
    activityLogs: [
      { date: "11-09-24 9:07", activity: "Last seen" },
      { date: "10-09-24 9:06", activity: "Location update" },
      { date: "09-09-24 9:06", activity: "Location update" },
      { date: "06-09-24 10:06", activity: "Location update" },
      { date: "05-09-24 9:06", activity: "Location update" },
      { date: "04-09-24 13:06", activity: "Location update" }
    ]
  },
  "FGV5340011802": {
    organization: "FGV",
    company: "",
    branch: "Johor",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVVESM00Y34202BDB7600",
      assetNumber: "FGV5340011802",
      hostname: "FGV5340011802",
      ipAddress: "192.168.0.76",
      macAddress: "04-E8-B9-16-DB-9A",
      publicIpAddress: "124.13.119.220",
      lastSeen: "10-09-24 8:43",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 133.42, available: 166.58 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 325.51, available: 374.49 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 1.4703,
      longitude: 103.903
    },
    assetHistory: [
      { dateTime: "10-09-24 8:43", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "09-09-24 8:58", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "08-09-24 0:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "07-09-24 13:42", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "06-09-24 15:25", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "05-09-24 8:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" }
    ],
    activityLogs: [
      { date: "10-09-24 8:43", activity: "Last seen" },
      { date: "09-09-24 8:58", activity: "Location update" },
      { date: "08-09-24 0:59", activity: "Location update" },
      { date: "07-09-24 13:42", activity: "Location update" },
      { date: "06-09-24 15:25", activity: "Location update" },
      { date: "05-09-24 8:59", activity: "Location update" }
    ]
  },
  "PC5430008094": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVPMSM01F126163F37600",
      assetNumber: "PC5430008094",
      hostname: "PC5430008094",
      ipAddress: "10.112.164.112",
      macAddress: "58-6C-25-52-24-39",
      publicIpAddress: "211.25.233.92",
      lastSeen: "11-09-24 8:21",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 182.27, available: 117.73 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 331.84, available: 368.16 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1776,
      longitude: 101.7056
    },
    assetHistory: [
      { dateTime: "11-09-24 8:21", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1776", longitude: "101.7056" },
      { dateTime: "09-09-24 19:01", location: "Petaling Jaya, Selangor", latitude: "3.1071", longitude: "101.6139" },
      { dateTime: "08-09-24 8:07", location: "Shah Alam, Selangor", latitude: "3.07328", longitude: "101.51846" },
      { dateTime: "07-09-24 8:59", location: "Shah Alam, Selangor", latitude: "3.07328", longitude: "101.51846" },
      { dateTime: "06-09-24 10:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.14992", longitude: "101.69446" }
    ],
    activityLogs: [
      { date: "11-09-24 8:21", activity: "Last seen" },
      { date: "09-09-24 19:01", activity: "Location update" },
      { date: "08-09-24 8:07", activity: "Location update" },
      { date: "07-09-24 8:59", activity: "Location update" },
      { date: "06-09-24 10:59", activity: "Location update" }
    ]
  },
  "PC5340010091": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVPMSM02A2160811E7600",
      assetNumber: "PC5340010091",
      hostname: "PC5340010091",
      ipAddress: "10.112.164.141",
      macAddress: "C8-CB-9E-43-08-E1",
      publicIpAddress: "211.25.233.82",
      lastSeen: "11-09-24 9:59",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 173.1, available: 126.9 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 345.43, available: 354.57 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 9:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "10-09-24 20:27", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 16:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 9:42", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "08-09-24 14:46", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 10:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 9:59", activity: "Last seen" },
      { date: "10-09-24 20:27", activity: "Location update" },
      { date: "09-09-24 16:59", activity: "Location update" },
      { date: "09-09-24 9:42", activity: "Location update" },
      { date: "08-09-24 14:46", activity: "Location update" },
      { date: "06-09-24 10:00", activity: "Location update" }
    ]
  },
  "FGV5340010875": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVPMSM02A23608D847600",
      assetNumber: "FGV5340010875",
      hostname: "FGV5340010875",
      ipAddress: "10.112.152.83",
      macAddress: "58-CE-2A-9E-90-85",
      publicIpAddress: "211.25.233.85",
      lastSeen: "11-09-24 8:19",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 158.35, available: 141.65 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 427.29, available: 272.71 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 8:19", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "10-09-24 9:50", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 14:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 11:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 8:19", activity: "Last seen" },
      { date: "10-09-24 9:50", activity: "Location update" },
      { date: "09-09-24 14:59", activity: "Location update" },
      { date: "06-09-24 11:00", activity: "Location update" }
    ]
  },
  "FGV5340012135": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVVESM00Y350047077600",
      assetNumber: "FGV5340012135",
      hostname: "FGV5340012135",
      ipAddress: "10.112.164.86",
      macAddress: "BC-03-58-BA-00-C2",
      publicIpAddress: "211.25.233.92",
      lastSeen: "11-09-24 8:40",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 136.49, available: 163.51 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 288.97, available: 411.03 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 8:40", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "10-09-24 10:36", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 7:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 11:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 8:40", activity: "Last seen" },
      { date: "10-09-24 10:36", activity: "Location update" },
      { date: "09-09-24 7:59", activity: "Location update" },
      { date: "06-09-24 11:00", activity: "Location update" }
    ]
  },
  "FLD5210236252": {
    organization: "FGV",
    company: "",
    branch: "Sabah",
    assetDetails: {
      type: "Laptop",
      serialNumber: "DTVWNSM00V316004729600",
      assetNumber: "FLD5210236252",
      hostname: "FLD5210236252",
      ipAddress: "192.168.1.40",
      macAddress: "50-28-4A-E1-ED-26",
      publicIpAddress: "183.171.158.138",
      lastSeen: "11-09-24 8:46",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22000",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 142.24, available: 157.76 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 315.66, available: 384.34 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 5.072,
      longitude: 119.049
    },
    assetHistory: [
      { dateTime: "11-09-24 8:46", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" },
      { dateTime: "10-09-24 14:59", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" }
    ],
    activityLogs: [
      { date: "11-09-24 8:46", activity: "Last seen" },
      { date: "10-09-24 14:59", activity: "Location update" }
    ]
  },
  "FGV5340012052": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVVESM00Y350046917600",
      assetNumber: "FGV5340012052",
      hostname: "FGV5340012052",
      ipAddress: "10.112.164.156",
      macAddress: "E0-C2-64-02-24-39",
      publicIpAddress: "211.25.233.84",
      lastSeen: "09-09-24 8:59",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 181.53, available: 118.47 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 380.13, available: 319.87 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "09-09-24 8:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "08-09-24 8:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "07-09-24 19:39", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 10:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "09-09-24 8:59", activity: "Last seen" },
      { date: "08-09-24 8:59", activity: "Location update" },
      { date: "07-09-24 19:39", activity: "Location update" },
      { date: "06-09-24 10:00", activity: "Location update" }
    ]
  },
  "FGV5340008117": {
    organization: "FGV",
    company: "",
    branch: "Johor",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVPMSM01F126164007600",
      assetNumber: "FGV5340008117",
      hostname: "FGV5340008117",
      ipAddress: "192.168.0.72",
      macAddress: "58-6C-25-41-FD-0C",
      publicIpAddress: "60.49.51.231",
      lastSeen: "11-09-24 8:41",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 185.24, available: 114.76 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 244.01, available: 455.99 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 1.4703,
      longitude: 103.903
    },
    assetHistory: [
      { dateTime: "11-09-24 8:41", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "10-09-24 9:58", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "09-09-24 8:11", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "07-09-24 22:14", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "05-09-24 8:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "04-09-24 13:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" }
    ],
    activityLogs: [
      { date: "11-09-24 8:41", activity: "Last seen" },
      { date: "10-09-24 9:58", activity: "Location update" },
      { date: "09-09-24 8:11", activity: "Location update" },
      { date: "07-09-24 22:14", activity: "Location update" },
      { date: "05-09-24 8:59", activity: "Location update" },
      { date: "04-09-24 13:59", activity: "Location update" }
    ]
  },
  "PC45324": {
    organization: "FGV",
    company: "",
    branch: "Johor",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVLFSM00A032134517600",
      assetNumber: "PC45324",
      hostname: "PC45324",
      ipAddress: "192.168.0.74",
      macAddress: "C8-B2-9B-B9-68-60",
      publicIpAddress: "60.49.51.231",
      lastSeen: "11-09-24 8:59",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 178.49, available: 121.51 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 323.6, available: 376.4 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 1.4703,
      longitude: 103.903
    },
    assetHistory: [
      { dateTime: "11-09-24 8:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "09-09-24 8:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "06-09-24 9:01", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "05-09-24 8:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "04-09-24 14:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" }
    ],
    activityLogs: [
      { date: "11-09-24 8:59", activity: "Last seen" },
      { date: "09-09-24 8:59", activity: "Location update" },
      { date: "06-09-24 9:01", activity: "Location update" },
      { date: "05-09-24 8:59", activity: "Location update" },
      { date: "04-09-24 14:59", activity: "Location update" }
    ]
  },
  "FLD5340012635": {
    organization: "FELDA",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "UNB0WSM040430030",
      assetNumber: "FLD5340012635",
      hostname: "FLD5340012635",
      ipAddress: "192.168.0.19",
      macAddress: "E4-C7-67-17-BD-35",
      publicIpAddress: "175.136.152.106",
      lastSeen: "12-09-24 3:00",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22000",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 105.85, available: 194.15 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 274.92, available: 425.08 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1552,
      longitude: 101.7189
    },
    assetHistory: [
      { dateTime: "12-09-24 3:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1552", longitude: "101.7189" },
      { dateTime: "11-09-24 8:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1552", longitude: "101.7189" },
      { dateTime: "09-09-24 22:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1552", longitude: "101.7189" },
      { dateTime: "06-09-24 8:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1552", longitude: "101.7189" }
    ],
    activityLogs: [
      { date: "12-09-24 3:00", activity: "Last seen" },
      { date: "11-09-24 8:00", activity: "Location update" },
      { date: "09-09-24 22:59", activity: "Location update" },
      { date: "06-09-24 8:00", activity: "Location update" }
    ]
  },
  "PC5340007096": {
    organization: "FGV",
    company: "",
    branch: "Johor",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVPMSM00D05236A3B7600",
      assetNumber: "PC5340007096",
      hostname: "PC5340007096",
      ipAddress: "10.232.41.181",
      macAddress: "68-3E-26-81-A9-2A",
      publicIpAddress: "202.188.66.160",
      lastSeen: "11-09-24 14:59",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22000",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 187.68, available: 112.32 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 265.21, available: 434.79 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 1.4703,
      longitude: 103.903
    },
    assetHistory: [
      { dateTime: "11-09-24 14:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "10-09-24 14:58", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "09-09-24 11:58", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "08-09-24 12:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "06-09-24 9:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "05-09-24 9:00", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "04-09-24 14:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" }
    ],
    activityLogs: [
      { date: "11-09-24 14:59", activity: "Last seen" },
      { date: "10-09-24 14:58", activity: "Location update" },
      { date: "09-09-24 11:58", activity: "Location update" },
      { date: "08-09-24 12:59", activity: "Location update" },
      { date: "06-09-24 9:59", activity: "Location update" },
      { date: "05-09-24 9:00", activity: "Location update" },
      { date: "04-09-24 14:59", activity: "Location update" }
    ]
  },
  "PC534004056": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVCKSM01274607A093400",
      assetNumber: "PC534004056",
      hostname: "PC534004056",
      ipAddress: "10.112.155.7",
      macAddress: "00-E1-8C-3F-08-59",
      publicIpAddress: "211.25.233.88",
      lastSeen: "11-09-24 1:00",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 153.06, available: 146.94 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 497.7, available: 202.3 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 1:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "10-09-24 0:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 11:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 11:12", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 1:00", activity: "Last seen" },
      { date: "10-09-24 0:59", activity: "Location update" },
      { date: "09-09-24 11:59", activity: "Location update" },
      { date: "06-09-24 11:12", activity: "Location update" }
    ]
  },
  "PC5340009993": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVPMSM02A20701E3E7600",
      assetNumber: "PC5340009993",
      hostname: "PC5340009993",
      ipAddress: "10.112.153.242",
      macAddress: "20-C1-9B-76-B8-AD",
      publicIpAddress: "211.25.233.84",
      lastSeen: "11-09-24 9:00",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 147.55, available: 152.45 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 291.98, available: 408.02 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 9:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "10-09-24 8:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 7:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 9:00", activity: "Last seen" },
      { date: "10-09-24 8:59", activity: "Location update" },
      { date: "09-09-24 7:59", activity: "Location update" }
    ]
  },
  "FGV5340012587": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXB0WSM00A420150B67600",
      assetNumber: "FGV5340012587",
      hostname: "FGV5340012587",
      ipAddress: "10.16.22.29",
      macAddress: "D4-F3-2D-1D-2E-78",
      publicIpAddress: "202.133.105.184",
      lastSeen: "10-09-24 15:22",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 124.23, available: 175.77 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 405.5, available: 294.5 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "10-09-24 15:22", location: "Petaling Jaya, Selangor", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 18:59", location: "Petaling Jaya, Selangor", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "10-09-24 15:22", activity: "Last seen" },
      { date: "09-09-24 18:59", activity: "Location update" }
    ]
  },
  "PC5340006326": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVLFSM00A0351B5757600",
      assetNumber: "PC5340006326",
      hostname: "PC5340006326",
      ipAddress: "10.10.159.211",
      macAddress: "08-D2-3E-E0-8B-25",
      publicIpAddress: "175.139.207.98",
      lastSeen: "11-09-24 13:08",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 116.54, available: 183.46 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 468.91, available: 231.09 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 13:08", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "10-09-24 9:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 8:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 11:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 13:08", activity: "Last seen" },
      { date: "10-09-24 9:59", activity: "Location update" },
      { date: "09-09-24 8:59", activity: "Location update" },
      { date: "06-09-24 11:00", activity: "Location update" }
    ]
  },
  "FGV5210237434": {
    organization: "FGV",
    company: "",
    branch: "Sabah",
    assetDetails: {
      type: "Laptop",
      serialNumber: "DTVY3SM00A40100E589600",
      assetNumber: "FGV5210237434",
      hostname: "FGV5210237434",
      ipAddress: "192.168.1.40",
      macAddress: "28-C5-D2-A6-45-50",
      publicIpAddress: "115.164.119.104",
      lastSeen: "09-09-24 14:58",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 166.3, available: 133.7 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 372.82, available: 327.18 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 5.072,
      longitude: 119.049
    },
    assetHistory: [
      { dateTime: "09-09-24 14:58", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" },
      { dateTime: "06-09-24 12:00", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" }
    ],
    activityLogs: [
      { date: "09-09-24 14:58", activity: "Last seen" },
      { date: "06-09-24 12:00", activity: "Location update" }
    ]
  },
  "FGV5340012144": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVVESM00Y350046E07600",
      assetNumber: "FGV5340012144",
      hostname: "FGV5340012144",
      ipAddress: "10.112.154.254",
      macAddress: "E0-C2-64-04-66-6D",
      publicIpAddress: "211.25.233.92",
      lastSeen: "12-09-24 1:00",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 140.08, available: 159.92 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 449.63, available: 250.37 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "12-09-24 1:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 0:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "08-09-24 0:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "07-09-24 1:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 11:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "12-09-24 1:00", activity: "Last seen" },
      { date: "09-09-24 0:59", activity: "Location update" },
      { date: "08-09-24 0:59", activity: "Location update" },
      { date: "07-09-24 1:00", activity: "Location update" },
      { date: "06-09-24 11:00", activity: "Location update" }
    ]
  },
  "FGV5210037060": {
    organization: "FGV",
    company: "",
    branch: "Sabah",
    assetDetails: {
      type: "Laptop",
      serialNumber: "DTVT9SM0041200277D9600",
      assetNumber: "FGV5210037060",
      hostname: "FGV5210037060",
      ipAddress: "10.113.55.114",
      macAddress: "84-5C-F3-A7-1E-1C",
      publicIpAddress: "113.210.105.38",
      lastSeen: "10-09-24 12:58",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22000",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 143.99, available: 156.01 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 375.55, available: 324.45 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 5.072,
      longitude: 119.049
    },
    assetHistory: [
      { dateTime: "10-09-24 12:58", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" }
    ],
    activityLogs: [
      { date: "10-09-24 12:58", activity: "Last seen" }
    ]
  },
  "LPC200610066": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVLFSM00A0100307A7600",
      assetNumber: "LPC200610066",
      hostname: "LPC200610066",
      ipAddress: "10.112.152.43",
      macAddress: "80-32-53-0E-F5-F1",
      publicIpAddress: "211.25.233.84",
      lastSeen: "11-09-24 7:00",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 120.67, available: 179.33 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 447.69, available: 252.31 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 7:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "10-09-24 6:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 6:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 11:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 7:00", activity: "Last seen" },
      { date: "10-09-24 6:59", activity: "Location update" },
      { date: "09-09-24 6:59", activity: "Location update" },
      { date: "06-09-24 11:00", activity: "Location update" }
    ]
  },
  "PC5340008946": {
    organization: "FGV",
    company: "",
    branch: "Johor",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVPMSM02A201185A17600",
      assetNumber: "PC5340008946",
      hostname: "PC5340008946",
      ipAddress: "192.168.0.47",
      macAddress: "C4-BD-E5-C1-DD-10",
      publicIpAddress: "60.49.51.231",
      lastSeen: "11-09-24 8:00",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 141.76, available: 158.24 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 262.14, available: 437.86 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 1.4703,
      longitude: 103.903
    },
    assetHistory: [
      { dateTime: "11-09-24 8:00", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "10-09-24 7:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "09-09-24 7:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "06-09-24 8:01", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "05-09-24 8:20", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" }
    ],
    activityLogs: [
      { date: "11-09-24 8:00", activity: "Last seen" },
      { date: "10-09-24 7:59", activity: "Location update" },
      { date: "09-09-24 7:59", activity: "Location update" },
      { date: "06-09-24 8:01", activity: "Location update" },
      { date: "05-09-24 8:20", activity: "Location update" }
    ]
  },
  "FGV5210038473": {
    organization: "FGV",
    company: "",
    branch: "Sabah",
    assetDetails: {
      type: "Laptop",
      serialNumber: "unknown",
      assetNumber: "FGV5210038473",
      hostname: "FGV5210038473",
      ipAddress: "10.113.212.150",
      macAddress: "C8-B2-9B-20-00-DA",
      publicIpAddress: "113.210.105.238",
      lastSeen: "11-09-24 10:01",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 153.35, available: 146.65 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 313.89, available: 386.11 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 5.072,
      longitude: 119.049
    },
    assetHistory: [
      { dateTime: "11-09-24 10:01", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" }
    ],
    activityLogs: [
      { date: "11-09-24 10:01", activity: "Last seen" }
    ]
  },
  "FGV5340006342": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVLFSM00A0321345F7600",
      assetNumber: "FGV5340006342",
      hostname: "FGV5340006342",
      ipAddress: "172.20.10.5",
      macAddress: "C8-B2-9B-B9-2A-17",
      publicIpAddress: "183.171.78.92",
      lastSeen: "11-09-24 15:51",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 176.87, available: 123.13 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 210.81, available: 489.19 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 15:51", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "10-09-24 8:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 8:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 11:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 15:51", activity: "Last seen" },
      { date: "10-09-24 8:00", activity: "Location update" },
      { date: "09-09-24 8:59", activity: "Location update" },
      { date: "06-09-24 11:00", activity: "Location update" }
    ]
  },
  "PC534001175": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVPMSM02A2160813B7600",
      assetNumber: "PC534001175",
      hostname: "PC534001175",
      ipAddress: "192.168.0.54",
      macAddress: "C8-CB-9E-42-84-A2",
      publicIpAddress: "103.21.32.202",
      lastSeen: "10-09-24 9:05",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 151.52, available: 148.48 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 433.6, available: 266.4 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "10-09-24 9:05", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 9:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 9:03", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "05-09-24 9:05", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "04-09-24 14:42", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "10-09-24 9:05", activity: "Last seen" },
      { date: "09-09-24 9:00", activity: "Location update" },
      { date: "06-09-24 9:03", activity: "Location update" },
      { date: "05-09-24 9:05", activity: "Location update" },
      { date: "04-09-24 14:42", activity: "Location update" }
    ]
  },
  "PC5340010276": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "UNVPMSM06K228077",
      assetNumber: "PC5340010276",
      hostname: "PC5340010276",
      ipAddress: "172.20.10.9",
      macAddress: "20-C1-9B-7F-6E-30",
      publicIpAddress: "183.171.70.228",
      lastSeen: "11-09-24 9:00",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22621",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 140.59, available: 159.41 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 492.82, available: 207.18 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 9:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 11:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 9:00", activity: "Last seen" },
      { date: "06-09-24 11:00", activity: "Location update" }
    ]
  },
  "LPC200602017": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVLFSM00A952124497600",
      assetNumber: "LPC200602017",
      hostname: "LPC200602017",
      ipAddress: "10.112.164.154",
      macAddress: "C0-B8-83-F5-57-94",
      publicIpAddress: "211.25.233.82",
      lastSeen: "11-09-24 15:00",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 128.63, available: 171.37 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 435.54, available: 264.46 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 15:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "11-09-24 9:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 11:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 15:00", activity: "Last seen" },
      { date: "11-09-24 9:00", activity: "Location update" },
      { date: "06-09-24 11:00", activity: "Location update" }
    ]
  },
  "FGV5210234919": {
    organization: "FGV",
    company: "",
    branch: "Sabah",
    assetDetails: {
      type: "Laptop",
      serialNumber: "DTVWUSM001225009463000",
      assetNumber: "FGV5210234919",
      hostname: "FGV5210234919",
      ipAddress: "192.168.1.63",
      macAddress: "98-EE-CB-FC-E8-5E",
      publicIpAddress: "183.171.158.101",
      lastSeen: "11-09-24 8:59",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22000",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 151.36, available: 148.64 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 307.09, available: 392.91 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 5.072,
      longitude: 119.049
    },
    assetHistory: [
      { dateTime: "11-09-24 8:59", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" },
      { dateTime: "10-09-24 10:58", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" }
    ],
    activityLogs: [
      { date: "11-09-24 8:59", activity: "Last seen" },
      { date: "10-09-24 10:58", activity: "Location update" }
    ]
  },
  "FGV5210234913": {
    organization: "FGV",
    company: "",
    branch: "Sabah",
    assetDetails: {
      type: "Laptop",
      serialNumber: "DTVWUSM001225009D23000",
      assetNumber: "FGV5210234913",
      hostname: "FGV5210234913",
      ipAddress: "192.168.1.215",
      macAddress: "54-6C-EB-09-FE-2F",
      publicIpAddress: "183.171.158.105",
      lastSeen: "11-09-24 7:59",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22000",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 120.49, available: 179.51 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 490.38, available: 209.62 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 5.072,
      longitude: 119.049
    },
    assetHistory: [
      { dateTime: "11-09-24 7:59", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" },
      { dateTime: "10-09-24 7:58", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" },
      { dateTime: "09-09-24 11:58", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" }
    ],
    activityLogs: [
      { date: "11-09-24 7:59", activity: "Last seen" },
      { date: "10-09-24 7:58", activity: "Location update" },
      { date: "09-09-24 11:58", activity: "Location update" }
    ]
  },
  "FGV5210237427": {
    organization: "FGV",
    company: "",
    branch: "Sabah",
    assetDetails: {
      type: "Laptop",
      serialNumber: "DTVY3SM00A40100E4F9600",
      assetNumber: "FGV5210237427",
      hostname: "FGV5210237427",
      ipAddress: "172.18.3.197",
      macAddress: "88-AE-DD-84-CC-BF",
      publicIpAddress: "113.210.105.90",
      lastSeen: "09-09-24 13:00",
      status: "Active",
      remarks: " "
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 199.61, available: 100.39 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 455.19, available: 244.81 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 5.072,
      longitude: 119.049
    },
    assetHistory: [
      { dateTime: "09-09-24 13:00", location: "Sabah, Sahabat", latitude: "5.072", longitude: "119.049" }
    ],
    activityLogs: [
      { date: "09-09-24 13:00", activity: "Last seen" }
    ]
  },
  "PC5340008355": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVPMSM01F1340334B7600",
      assetNumber: "PC5340008355",
      hostname: "PC5340008355",
      ipAddress: "10.112.184.21",
      macAddress: "14-85-7F-78-D6-74",
      publicIpAddress: "211.25.233.90",
      lastSeen: "11-09-24 0:00",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 129.43, available: 170.57 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 474.16, available: 225.84 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 0:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 14:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "08-09-24 22:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 11:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 0:00", activity: "Last seen" },
      { date: "09-09-24 14:59", activity: "Location update" },
      { date: "08-09-24 22:59", activity: "Location update" },
      { date: "06-09-24 11:00", activity: "Location update" }
    ]
  },
  "FGVLPC200403013": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVLUSM001010003F17600",
      assetNumber: "FGVLPC200403013",
      hostname: "FGVLPC200403013",
      ipAddress: "10.112.165.88",
      macAddress: "44-AF-28-CD-23-DC",
      publicIpAddress: "211.25.233.90",
      lastSeen: "11-09-24 9:50",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 187.07, available: 112.93 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 289.71, available: 410.29 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 9:50", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "10-09-24 10:05", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 13:09", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 9:50", activity: "Last seen" },
      { date: "10-09-24 10:05", activity: "Location update" },
      { date: "09-09-24 13:09", activity: "Location update" }
    ]
  },
  "PC5340009204": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVPMSM02A20701EF77600",
      assetNumber: "PC5340009204",
      hostname: "PC5340009204",
      ipAddress: "10.112.154.187",
      macAddress: "20-C1-9B-77-0C-E0",
      publicIpAddress: "211.25.233.90",
      lastSeen: "09-09-24 10:01",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 146.98, available: 153.02 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 477.07, available: 222.93 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "09-09-24 10:01", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 11:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "09-09-24 10:01", activity: "Last seen" },
      { date: "06-09-24 11:00", activity: "Location update" }
    ]
  },
  "PC5340005246": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVEESM0038390721C7200",
      assetNumber: "PC5340005246",
      hostname: "PC5340005246",
      ipAddress: "10.112.165.108",
      macAddress: "64-5D-86-D4-74-F7",
      publicIpAddress: "211.25.233.84",
      lastSeen: "11-09-24 15:59",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 115.32, available: 184.68 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 291.65, available: 408.35 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 15:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "05-09-24 7:45", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "04-09-24 10:39", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "03-09-24 15:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 15:59", activity: "Last seen" },
      { date: "05-09-24 7:45", activity: "Location update" },
      { date: "04-09-24 10:39", activity: "Location update" },
      { date: "03-09-24 15:59", activity: "Location update" }
    ]
  },
  "PC5340009205": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVPMSM02A20701F247600",
      assetNumber: "PC5340009205",
      hostname: "PC5340009205",
      ipAddress: "10.112.152.203",
      macAddress: "20-C1-9B-77-6E-E2",
      publicIpAddress: "211.25.233.88",
      lastSeen: "11-09-24 7:59",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22000",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 164.93, available: 135.07 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 253.4, available: 446.6 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "11-09-24 7:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "10-09-24 8:58", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "09-09-24 9:10", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 12:02", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "11-09-24 7:59", activity: "Last seen" },
      { date: "10-09-24 8:58", activity: "Location update" },
      { date: "09-09-24 9:10", activity: "Location update" },
      { date: "06-09-24 12:02", activity: "Location update" }
    ]
  },
  "LPC200615121": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVLFSM00A0100308A7600",
      assetNumber: "LPC200615121",
      hostname: "LPC200615121",
      ipAddress: "192.168.1.15",
      macAddress: "80-32-53-0E-F2-72",
      publicIpAddress: "113.210.102.254",
      lastSeen: "12-09-24 0:00",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19042",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 158.04, available: 141.96 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 414.99, available: 285.01 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "12-09-24 0:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "11-09-24 9:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" },
      { dateTime: "06-09-24 15:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "12-09-24 0:00", activity: "Last seen" },
      { date: "11-09-24 9:00", activity: "Location update" },
      { date: "06-09-24 15:00", activity: "Location update" }
    ]
  },
  "fld5340012630": {
    organization: "FELDA",
    company: "FPM",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXB0WSM00A420150E67600",
      assetNumber: "fld5340012630",
      hostname: "fld5340012630",
      ipAddress: "172.26.39.56",
      macAddress: "74-D4-DD-63-B0-8D",
      publicIpAddress: "NULL",
      lastSeen: "05-09-24 15:59",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22000",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 115.74, available: 184.26 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 432.85, available: 267.15 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1552,
      longitude: 101.7189
    },
    assetHistory: [
      { dateTime: "05-09-24 15:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1552", longitude: "101.7189" },
      { dateTime: "05-09-24 15:59", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1552", longitude: "101.7189" }
    ],
    activityLogs: [
      { date: "05-09-24 15:59", activity: "Last seen" },
      { date: "05-09-24 15:59", activity: "Location update" }
    ]
  },
  "PC43521": {
    organization: "FGV",
    company: "",
    branch: "Johor",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVLFSM00A0321344C7600",
      assetNumber: "PC43521",
      hostname: "PC43521",
      ipAddress: "192.168.0.126",
      macAddress: "C8-B2-9B-AE-53-03",
      publicIpAddress: "124.13.119.220",
      lastSeen: "06-09-24 8:59",
      status: "Active",
      remarks: "Actual is 5340006298"
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 11 build 22631",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 159.23, available: 140.77 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 478.2, available: 221.8 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 1.4703,
      longitude: 103.903
    },
    assetHistory: [
      { dateTime: "06-09-24 8:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "05-09-24 8:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" },
      { dateTime: "04-09-24 16:59", location: "Johor, Pasir Gudang", latitude: "1.4703", longitude: "103.903" }
    ],
    activityLogs: [
      { date: "06-09-24 8:59", activity: "Last seen" },
      { date: "05-09-24 8:59", activity: "Location update" },
      { date: "04-09-24 16:59", activity: "Location update" }
    ]
  },
  "PC5340010874": {
    organization: "FGV",
    company: "",
    branch: "HQ",
    assetDetails: {
      type: "Laptop",
      serialNumber: "NXVPMSM02A23608D227600",
      assetNumber: "PC5340010874",
      hostname: "PC5340010874",
      ipAddress: "10.112.165.151",
      macAddress: "58-CE-2A-9F-0B-EB",
      publicIpAddress: "211.25.233.88",
      lastSeen: "06-09-24 11:00",
      status: "Active",
      remarks: ""
    },
    assetInfo: {
      operatingSystem: "Microsoft Windows 10 build 19045",
      systemType: "64-bit operating system, x64-based processor",
      processor: "13th gen Intel(R) Core(TM) i5-13335u",
      RAM: "8.0 GB (7.8 GB usable)",
      storage: [
        { name: "OS (C)", type: "HDD", capacity: 300.0, used: 193.11, available: 106.89 },
        { name: "Data (D)", type: "HDD", capacity: 700.0, used: 347.46, available: 352.54 }
      ]
    },
    installedSoftware: [
      { name: "Google Chrome", version: "114.0.5735.199" },
      { name: "Microsoft Teams", version: "1.0.0.2024050900" },
      { name: "Microsoft Outlook", version: "16.0.16130.20218" }
    ],
    assetLocation: {
      latitude: 3.1617,
      longitude: 101.6949
    },
    assetHistory: [
      { dateTime: "06-09-24 11:00", location: "Kuala Lumpur, Kuala Lumpur", latitude: "3.1617", longitude: "101.6949" }
    ],
    activityLogs: [
      { date: "06-09-24 11:00", activity: "Last seen" }
    ]
  },
};
