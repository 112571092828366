import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, Typography, Paper, Grid, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Button, IconButton, Toolbar,
  List, ListItem, ListItemIcon, ListItemText, TextField, Link,
  Dialog, DialogTitle, DialogContent, DialogActions, Menu, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StarIcon from '@mui/icons-material/Star';
import EmailIcon from '@mui/icons-material/Email';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ExitToApp } from '@mui/icons-material';
import { laptopDetailData } from './staticLaptopDetailData';
import SFSLogo from './images/SecureFlowSense_logo_nobg.png';
import GoogleMap from './GoogleMap';

const LaptopDetailRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: '#111315',
  minHeight: '100vh',
  color: '#FFFFFF',
}));

const Sidebar = styled(Box)(({ theme }) => ({
  width: 265,
  backgroundColor: '#1A1D1F',
  padding: theme.spacing(2),
}));

const MainContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const InfoCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: '#1A1D1F',
  color: '#FFFFFF',
  borderRadius: '10px',
  border: '1px solid #FF7E00',
}));

const StorageBar = styled(Box)(({ theme, usedPercentage }) => ({
  height: '20px',
  width: '100%',
  backgroundColor: '#444',
  borderRadius: '10px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: `${usedPercentage}%`,
    backgroundColor: usedPercentage < 70 ? '#4ECDC4' : '#FF9364',
    borderRadius: '10px',
  },
}));

const StorageDisplay = ({ drive }) => {
  const usedPercentage = (drive.used / drive.capacity) * 100;

  return (
    <Box sx={{ mt: 2 }}>
      <Typography>
        {`${drive.name} (${drive.type})`}: {`${drive.capacity.toFixed(2)} GB`}
      </Typography>
      <StorageBar usedPercentage={usedPercentage}>
      </StorageBar>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
        <Typography variant="caption">&nbsp;{`${drive.used.toFixed(2)} GB used`}</Typography>
        <Typography variant="caption">{`${drive.available.toFixed(2)} GB free`}</Typography>
      </Box>
    </Box>
  );
};

const LaptopDetailAssets = () => {
  const { assetNumber } = useParams();
  const navigate = useNavigate();
  const [assetData, setAssetData] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [mapZoom, setMapZoom] = useState(10);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showMACAddress, setShowMACAddress] = useState(false);
  const [showPublicIP, setShowPublicIP] = useState(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [currentFieldToUnmask, setCurrentFieldToUnmask] = useState('');
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [remarks, setRemarks] = useState('');
  const [isDataModified, setIsDataModified] = useState(false);

  useEffect(() => {
    const foundAsset = Object.values(laptopDetailData).find(
      asset => asset.assetDetails.assetNumber === assetNumber
    );
    
    if (foundAsset) {
      setAssetData(foundAsset);
      setRemarks(foundAsset.assetDetails.remarks || '');
      setMapCenter({
        lat: foundAsset.assetLocation.latitude,
        lng: foundAsset.assetLocation.longitude
      });
      setMapZoom(15);
    } else {
      console.error(`No data found for asset ID: ${assetNumber}`);
    }
  }, [assetNumber]);

  if (!assetData) {
    return <div>Loading...</div>;
  }

  const handleLocationClick = (location) => {
    setSelectedLocation(location);
    setMapCenter({
      lat: parseFloat(location.latitude),
      lng: parseFloat(location.longitude)
    });
    setMapZoom(15);
  };

  const mapMarkers = assetData.assetHistory.map(history => ({
    latitude: parseFloat(history.latitude),
    longitude: parseFloat(history.longitude),
    location: history.location,
    lastSeen: history.dateTime,
  }));

  const handleToggleVisibility = (field) => {
    if (field === 'MAC') {
      if (showMACAddress) {
        setShowMACAddress(false);
      } else {
        setCurrentFieldToUnmask('MAC');
        setPasswordDialogOpen(true);
      }
    } else if (field === 'IP') {
      if (showPublicIP) {
        setShowPublicIP(false);
      } else {
        setCurrentFieldToUnmask('IP');
        setPasswordDialogOpen(true);
      }
    }
  };

  const handlePasswordSubmit = () => {
    if (password === 'P@ssw0rd') { // Replace with actual password check
      if (currentFieldToUnmask === 'MAC') {
        setShowMACAddress(true);
      } else if (currentFieldToUnmask === 'IP') {
        setShowPublicIP(true);
      }
      setPasswordDialogOpen(false);
      setPassword('');
    } else {
      alert('Incorrect password');
    }
  };

  const handleStatusClick = (event) => {
    setStatusAnchorEl(event.currentTarget);
  };

  const handleStatusClose = () => {
    setStatusAnchorEl(null);
  };

  const handleStatusChange = (newStatus) => {
    setAssetData(prevData => ({
      ...prevData,
      assetDetails: {
        ...prevData.assetDetails,
        status: newStatus
      }
    }));
    handleStatusClose();
  };

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
    setIsDataModified(true);
  };

  const handleSave = () => {
    const updatedAssetData = {
      ...laptopDetailData[assetNumber],
      assetDetails: {
        ...laptopDetailData[assetNumber].assetDetails,
        remarks: remarks
      }
    };

    // Update the staticLaptopDetailData
    laptopDetailData[assetNumber] = updatedAssetData;

    // Update local state
    setAssetData(updatedAssetData);
    setIsDataModified(false);
    console.log('Data saved:', updatedAssetData);
  };

  const sidebarItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: '/home' },
    { text: 'Assets', icon: <InventoryIcon />, active: true, link: '/assets' },
    { text: 'Reports', icon: <AssessmentIcon />, link: '/reports' },
    { text: 'Request List', icon: <StarIcon />, link: '/request-list' },
    { text: 'Audit Logs', icon: <EmailIcon />, link: '/audit-logs' },
    { text: 'Settings', icon: <SettingsIcon />, link: '/settings' },
  ];

  const handleSidebarItemClick = (item) => {
    if (item.link) {
      navigate(item.link);
    }
  };

  const handleLogout = () => {
    navigate('/login');
  };

  return (
    <LaptopDetailRoot>
      <Sidebar>
        <Box sx={{ width: '200px', marginBottom: '24px' }}>
          <img src={SFSLogo} alt="SecureFlowSense" style={{ width: '100%' }} />
        </Box>
        <List>
          {sidebarItems.map((item) => (
            <ListItem 
              button 
              key={item.text}
              onClick={() => handleSidebarItemClick(item)}
              sx={{ 
                color: item.active ? '#FF7E00' : 'inherit',
                '&:hover': { backgroundColor: 'rgba(255, 126, 0, 0.1)' },
              }}
            >
              <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Sidebar>
      <MainContent>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Typography variant="h4" sx={{ mb: 3 }}>Asset Number: {assetData.assetDetails.assetNumber}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" component="span" sx={{ mr: 2, color: 'white' }}>
              Admin
            </Typography>
            <IconButton color="inherit" sx={{ color: 'white' }} onClick={handleLogout}>
              <ExitToApp />
            </IconButton>
          </Box> 
        </Toolbar>
        <Typography variant="h5" sx={{ mb: 3 }}>&nbsp;&nbsp;&nbsp;
          Organization: {assetData.organization} &nbsp;&nbsp;Company: {assetData.company} &nbsp;&nbsp;Branch: {assetData.branch}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <InfoCard>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6"><b>Asset Details: {assetData.assetDetails.type}</b></Typography>
              </Box>
              {Object.entries(assetData.assetDetails).map(([key, value]) => {
                if (key === 'remarks') return null; // Skip remarks, we'll handle it separately
                if (key === 'assetNumber') return null;// Skip rendering if the key is 'assetNumber'
                return (
                  <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, pl: 2 }}>
                    <Typography sx={{ flex: 1 }}>{key.charAt(0).toUpperCase() + key.slice(1).split(/(?=[A-Z])/).join(" ")}</Typography>
                    <Typography sx={{ flex: 2 }}>
                      : {
                        key === 'macAddress' ? (
                          <>
                            {showMACAddress ? value : '••••••••••••••'}
                            <IconButton size="small" sx={{ color: 'white' }} onClick={() => handleToggleVisibility('MAC')}>
                              {showMACAddress ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          </>
                        ) : key === 'publicIpAddress' ? (
                          <>
                            {showPublicIP ? value : '•••••••••••'}
                            <IconButton size="small" sx={{ color: 'white' }} onClick={() => handleToggleVisibility('IP')}>
                              {showPublicIP ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          </>
                        ) : key === 'status' ? (
                          <>
                            {value}
                            <IconButton size="small" sx={{ color: 'white' }} onClick={handleStatusClick}>
                              <ArrowDropDownIcon />
                            </IconButton>
                            <Menu
                              anchorEl={statusAnchorEl}
                              open={Boolean(statusAnchorEl)}
                              onClose={handleStatusClose}
                            >
                              <MenuItem onClick={() => handleStatusChange('Repair')}>Repair</MenuItem>
                              <MenuItem onClick={() => handleStatusChange('Return')}>Return</MenuItem>
                              <MenuItem onClick={() => handleStatusChange('Lost')}>Lost</MenuItem>
                            </Menu>
                          </>
                        ) : value
                      }
                    </Typography>
                  </Box>
                );
              })}
              <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">Remarks</Typography>
              <TextField
                  fullWidth
                  multiline
                  rows={2}
                  variant="outlined"
                  value={remarks}
                  onChange={handleRemarksChange}
                  sx={{
                    mt: 1,
                    bgcolor: '#2C2F33',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#2C2F33',
                      },
                      '&:hover fieldset': {
                        borderColor: '#2C2F33',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#2C2F33',
                      },
                    },
                  }}
                  InputProps={{
                  style: { color: 'white' },
                    }}
                  />
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleSave} 
                  sx={{ mt: 1 }}
                  disabled={!isDataModified}
                >
                  Save
                </Button>
              </Box>
            </InfoCard>
          </Grid>
          <Grid item xs={6} md={6} container spacing={3}>
            <Grid item xs={12} md={12}>
              <InfoCard>
                <Typography variant="h6" sx={{ mb: 2 }}><b>Asset Info</b></Typography>
                {Object.entries(assetData.assetInfo).map(([key, value]) => {
                  if (key !== 'storage') {
                    return (
                      <Box key={key} sx={{ mb: 1 }}>
                        <Typography>
                          {key === 'RAM' 
                            ? 'RAM'
                            : key.charAt(0).toUpperCase() + key.slice(1).split(/(?=[A-Z])/).join(" ")
                          }: {value}
                        </Typography>
                      </Box>
                    );
                  }
                  return null;
                })}
                <Grid container spacing={2}>
                  {assetData.assetInfo.storage.map((drive, index) => (
                    <Grid item xs={6} key={index}>
                      <StorageDisplay drive={drive} />
                    </Grid>
                  ))}
                </Grid>
              </InfoCard>
            </Grid>
            <Grid item xs={12} md={12}>
              <InfoCard>
                <Typography variant="h6" sx={{ mb: 1 }}><b>Installed Software</b></Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {assetData.installedSoftware.map((software, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ color: 'white', borderBottom: 'none' }}>{software.name}</TableCell>
                        </TableRow>                        
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </InfoCard>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoCard>
              <Typography variant="h6" sx={{ mb: 2 }}><b>Asset Location</b></Typography>
              <Box sx={{ height: 500 }}>
                <GoogleMap
                  center={mapCenter}
                  zoom={mapZoom}
                  markers={mapMarkers}
                />
              </Box>
            </InfoCard>
          </Grid>
          <Grid item xs={6} md={6} container spacing={3}>
            <Grid item xs={12} md={12}>
              <InfoCard>
                <Typography variant="h6" sx={{ mb: 2 }}><b>Asset History</b></Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: 'white', borderBottom: '2px solid #F6983D' }}>Date / Time</TableCell>
                        <TableCell sx={{ color: 'white', borderBottom: '2px solid #F6983D' }}>Location</TableCell>
                        <TableCell sx={{ color: 'white', borderBottom: '2px solid #F6983D' }}>Longitude</TableCell>
                        <TableCell sx={{ color: 'white', borderBottom: '2px solid #F6983D' }}>Latitude</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {assetData.assetHistory.map((history, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ color: 'white', borderBottom: 'none' }}>{history.dateTime}</TableCell>
                          <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => handleLocationClick({
                                latitude: history.latitude,
                                longitude: history.longitude,
                                location: history.location,
                                lastSeen: history.dateTime,
                              })}
                              sx={{ color: '#FF7E00' }}
                            >
                              {history.location}
                            </Link>
                          </TableCell>
                          <TableCell sx={{ color: 'white', borderBottom: 'none' }}>{history.longitude}</TableCell>
                          <TableCell sx={{ color: 'white', borderBottom: 'none' }}>{history.latitude}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </InfoCard>
            </Grid>
            <Grid item xs={12} md={12}>
              <InfoCard>
                  <Typography variant="h6" sx={{ mb: 2 }}><b>Activity Logs</b></Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: 'white', borderBottom: '2px solid #F6983D' }}>Date</TableCell>
                        <TableCell sx={{ color: 'white', borderBottom: '2px solid #F6983D' }}>Activity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {assetData.activityLogs.map((log, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ color: 'white', borderBottom: 'none' }}>{log.date}</TableCell>
                          <TableCell sx={{ color: 'white', borderBottom: 'none' }}>{log.activity}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </InfoCard>
            </Grid>
          </Grid>
        </Grid>
      </MainContent>
      <Dialog open={passwordDialogOpen} onClose={() => setPasswordDialogOpen(false)}>
        <DialogTitle>Enter Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPasswordDialogOpen(false)}>Cancel</Button>
          <Button onClick={handlePasswordSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </LaptopDetailRoot>
  );
};

export default LaptopDetailAssets;