import React from 'react';
import styled from 'styled-components';
import { TextField, Button, Grid } from '@mui/material';

const SectionContainer = styled.div`
  background-color: #1A1D1F;
  padding: 24px;
  border-radius: 8px;
  margin-top: 24px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: 16px;
  .MuiInputBase-root {
    color: #fff;
    background-color: #111315;
  }
  .MuiInputLabel-root {
    color: #fff;
  }
`;

const SaveButton = styled(Button)`
  background-color: #FF7E00;
  color: #fff;
  &:hover {
    background-color: #E67100;
  }
`;

const SMTPConfigSection = () => {
  return (
    <SectionContainer>
      <StyledTextField label="SMTP Server Address" value="smtp.gmail.com" variant="outlined" />
      <StyledTextField label="Username" value="admin@gmail.com" variant="outlined" />
      <StyledTextField label="Password" type="password" value="********" variant="outlined" />
      <div style={{ display: 'flex', gap: '16px' }}>
        <StyledTextField label="Port Number" value="587" variant="outlined" style={{ width: '50%' }} />
        <StyledTextField label="Encryption" value="TLS" variant="outlined" style={{ width: '50%' }} />
      </div>
      <Grid item xs={12}>
            <SaveButton type="submit" variant="contained">
              Save Changes
            </SaveButton>
          </Grid>
    </SectionContainer>
  );
};

export default SMTPConfigSection;