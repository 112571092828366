import React, { useState, useEffect } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { Typography, Button, TextField, Grid } from '@mui/material';

const UserProfileRoot = styled.div`
  background-color: #1A1D1F;
  padding: 24px;
  border-radius: 8px;
  width: 80%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
`;

const FormTitle = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
  color: #fff;
`;

const StyledTextField = muiStyled(TextField)`
  width: 100%;
  .MuiInputBase-root {
    color: #fff;
    background-color: #111315;
  }
  .MuiInputLabel-root {
    color: #fff;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #2A2D2F;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
`;

const SaveButton = styled(Button)`
  background-color: #FF7E00;
  color: #fff;
  &:hover {
    background-color: #E67100;
  }
`;

const CancelButton = styled(Button)`
  background-color: #555;
  color: #fff;
  &:hover {
    background-color: #777;
  }
`;

const UserProfileSection = ({ user, onClose }) => {
  const [userProfile, setUserProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
    role: '',
    department: '',
  });

  useEffect(() => {
    if (user) {
      const [firstName, lastName] = user.name.split(' ');
      setUserProfile({
        firstName,
        lastName,
        email: user.email,
        phoneNumber: user.phoneNumber || '',
        role: user.role,
        department: user.department || '',
        password: '',
        confirmPassword: '',
      });
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserProfile(prevProfile => ({
      ...prevProfile,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('User profile submitted:', userProfile);
    // Here you would typically send the data to your backend
    onClose();
  };

  return (
    <UserProfileRoot>
      <FormTitle>User Profile</FormTitle>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
            <StyledTextField
              name="firstName"
              label="First Name"
              value="Admin"
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              name="lastName"
              label="Last Name"
              value="Last Name"
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              name="email"
              label="Email"
              type="email"
              value="admin@gmail.com"
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              name="password"
              label="Password"
              type="password"
              value="********"
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              value="********"
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              name="phoneNumber"
              label="Phone Number"
              value="+609123456789"
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              name="role"
              label="Role"
              value="Administrator"
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledTextField
              name="department"
              label="Department"
              value="IT"
              onChange={handleInputChange}
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <ButtonContainer>
          <CancelButton variant="contained" onClick={onClose}>Cancel</CancelButton>
          <SaveButton type="submit" variant="contained">Save Changes</SaveButton>
        </ButtonContainer>
      </form>
    </UserProfileRoot>
  );
};

export default UserProfileSection;