import React, { useState } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import {
  TextField, IconButton, Box, List, ListItem, ListItemIcon, ListItemText, Typography, Toolbar,
  Button, Select, MenuItem, Grid, Paper, OutlinedInput, Checkbox
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StarIcon from '@mui/icons-material/Star';
import EmailIcon from '@mui/icons-material/Email';
import SettingsIcon from '@mui/icons-material/Settings';
import { ExitToApp, GetApp, PictureAsPdf, Print } from '@mui/icons-material';
import { laptopDetailData } from './staticLaptopDetailData';
import { useNavigate } from 'react-router-dom';
import SFSLogo from './images/SecureFlowSense_logo_nobg.png';

const ReportsRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: '#111315',
  color: '#FFFFFF',
  minHeight: '100vh',
}));

const Sidebar = muiStyled(Box)(({ theme }) => ({
  width: 210,
  backgroundColor: '#1A1D1F',
  padding: theme.spacing(2),
}));

const MainContent = styled.main`
  flex: 1;
  padding: 24px;
`;

const FormContainer = styled(Paper)`
  background-color: #1A1D1F;
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 24px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  background-color: #111315;
  color: #fff;
  .MuiSelect-icon {
    color: #fff;
  }
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  background-color: #111315;
  color: #fff;
  .MuiInputBase-input {
    color: #fff;
  }
  .MuiInputLabel-root {
    color: #fff;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #fff;
  }
`;

const GenerateButton = styled(Button)`
  background-color: #FF7E00;
  color: #fff;
  &:hover {
    background-color: #E67100;
  }
`;

const ClearButton = styled(Button)`
  background-color: #555;
  color: #fff;
  margin-left: 10px;
  &:hover {
    background-color: #777;
  }
`;

const ReportTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1px;
  margin-top: 24px;
`;

const Th = styled.th`
  text-align: left;
  padding: 16px;
  background-color: #1A1D1F;
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const Td = styled.td`
  padding: 16px;
  background-color: #1A1D1F;
`;

const Reports = () => {
  const [filters, setFilters] = useState({
    organization: [],
    company: [],
    branch: [],
    assetNumber: '',
    serialNumber: '',
    year: ''
  });
  const [reportData, setReportData] = useState(null);
  const navigate = useNavigate();

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prev => ({
      ...prev,
      [name]: Array.isArray(value) ? value : [value]
    }));
  };

  const generateReport = () => {
    const filteredData = Object.values(laptopDetailData).filter(asset => {
      return (
        (filters.organization.length === 0 || filters.organization.includes(asset.organization)) &&
        (filters.company.length === 0 || filters.company.includes(asset.company)) &&
        (filters.branch.length === 0 || filters.branch.includes(asset.branch)) &&
        (!filters.assetNumber || asset.assetDetails.assetNumber.includes(filters.assetNumber)) &&
        (!filters.serialNumber || asset.assetDetails.serialNumber.includes(filters.serialNumber)) &&
        (!filters.year || asset.assetDetails.lastSeen.includes(filters.year))
      );
    });

    const report = {
      totalActive: filteredData.filter(asset => asset.assetDetails.status === 'Active').length,
      totalInactive: filteredData.filter(asset => asset.assetDetails.status !== 'Active').length,
      assets: filteredData
    };

    setReportData(report);
  };

  const clearFilters = () => {
    setFilters({
      organization: [],
      company: [],
      branch: [],
      assetNumber: '',
      serialNumber: '',
      year: ''
    });
    setReportData(null);
  };

  const downloadCSV = () => {
    if (!reportData) return;

    const headers = ['Asset Number', 'Serial Number', 'Organization', 'Company', 'Branch', 'Status', 'Last Seen'];
    const csvContent = [
      headers.join(','),
      ...reportData.assets.map(asset => [
        asset.assetDetails.assetNumber,
        asset.assetDetails.serialNumber,
        asset.organization,
        asset.company,
        asset.branch,
        asset.assetDetails.status,
        asset.assetDetails.lastSeen
      ].join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'asset_report.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const downloadPDF = () => {
    // Note: In a real-world scenario, you'd use a library like jsPDF to generate the PDF
    console.log('Downloading PDF...');
  };

  const printReport = () => {
    window.print();
  };

  const sidebarItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: '/home' },
    { text: 'Assets', icon: <InventoryIcon />, link: '/assets' },
    { text: 'Reports', icon: <AssessmentIcon />, active: true, link: '/reports' },
    { text: 'Request List', icon: <StarIcon />, link: '/request-list' },
    { text: 'Audit Logs', icon: <EmailIcon />, link: '/audit-logs' },
    { text: 'Settings', icon: <SettingsIcon />, link: '/settings' },
  ];

  const handleSidebarItemClick = (item) => {
    if (item.link) {
      navigate(item.link);
    }
  };

  const handleLogout = () => {
    navigate('/login');
  };

  return (
    <ReportsRoot>
      <Sidebar>
        <Box sx={{ width: '200px', marginBottom: '24px' }}>
          <img src={SFSLogo} alt="SecureFlowSense" style={{ width: '100%' }} />
        </Box>
        <List>
          {sidebarItems.map((item) => (
            <ListItem 
              button 
              key={item.text}
              onClick={() => handleSidebarItemClick(item)}
              sx={{ 
                color: item.active ? '#FF7E00' : 'inherit',
                '&:hover': { backgroundColor: 'rgba(255, 126, 0, 0.1)' },
              }}
            >
              <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Sidebar>
      <MainContent>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Typography variant="h4" sx={{ mb: 3 }}>REPORTS</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" component="span" sx={{ mr: 2, color: 'white' }}>
              Admin
            </Typography>
            <IconButton color="inherit" sx={{ color: 'white' }} onClick={handleLogout}>
              <ExitToApp />
            </IconButton>
          </Box>
        </Toolbar>

        <FormContainer>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
              <StyledSelect
                multiple
                value={filters.organization}
                onChange={handleFilterChange}
                input={<OutlinedInput />}
                renderValue={(selected) => selected.length === 0 ? 'Select Organization' : selected.join(', ')}
                displayEmpty
                fullWidth
                name="organization"
              >
                <MenuItem disabled value="">
                  <em>Organization</em>
                </MenuItem>
                {['FGV', 'FELDA'].map((org) => (
                  <MenuItem key={org} value={org}>
                    <Checkbox checked={filters.organization.indexOf(org) > -1} />
                    <ListItemText primary={org} />
                  </MenuItem>
                ))}
              </StyledSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledSelect
                multiple
                value={filters.company}
                onChange={handleFilterChange}
                input={<OutlinedInput />}
                renderValue={(selected) => selected.length === 0 ? 'Select Company' : selected.join(', ')}
                displayEmpty
                fullWidth
                name="company"
              >
                <MenuItem disabled value="">
                  <em>Company</em>
                </MenuItem>
                {['FPM'].map((comp) => (
                  <MenuItem key={comp} value={comp}>
                    <Checkbox checked={filters.company.indexOf(comp) > -1} />
                    <ListItemText primary={comp} />
                  </MenuItem>
                ))}
              </StyledSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledSelect
                multiple
                value={filters.branch}
                onChange={handleFilterChange}
                input={<OutlinedInput />}
                renderValue={(selected) => selected.length === 0 ? 'Select Branch' : selected.join(', ')}
                displayEmpty
                fullWidth
                name="branch"
              >
                <MenuItem disabled value="">
                  <em>Branch</em>
                </MenuItem>
                {['HQ', 'Johor', 'Sabah'].map((branch) => (
                  <MenuItem key={branch} value={branch}>
                    <Checkbox checked={filters.branch.indexOf(branch) > -1} />
                    <ListItemText primary={branch} />
                  </MenuItem>
                ))}
              </StyledSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledTextField
                label="Serial Number"
                value={filters.serialNumber}
                onChange={handleFilterChange}
                name="serialNumber"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledTextField
                label="Year"
                value={filters.year}
                onChange={handleFilterChange}
                name="year"
              />
            </Grid>
            <Grid item xs={12}>
              <GenerateButton onClick={generateReport}>Generate Report</GenerateButton>
              <ClearButton onClick={clearFilters}>Clear</ClearButton>
            </Grid>
          </Grid>
        </FormContainer>

        {reportData && (
          <>
            <ReportTable>
              <thead>
                <tr>
                  <Th colSpan="7">Asset Report</Th>
                </tr>
                <tr>
                  <Th>Total Active: {reportData.totalActive}</Th>
                  <Th>Total Inactive: {reportData.totalInactive}</Th>
                  <Th colSpan="5"></Th>
                </tr>
                <tr>
                  <Th>Asset Number</Th>
                  <Th>Serial Number</Th>
                  <Th>Organization</Th>
                  <Th>Company</Th>
                  <Th>Branch</Th>
                  <Th>Status</Th>
                  <Th>Last Seen</Th>
                </tr>
              </thead>
              <tbody>
                {reportData.assets.map((asset, index) => (
                  <tr key={index}>
                    <Td>{asset.assetDetails.assetNumber}</Td>
                    <Td>{asset.assetDetails.serialNumber}</Td>
                    <Td>{asset.organization}</Td>
                    <Td>{asset.company}</Td>
                    <Td>{asset.branch}</Td>
                    <Td>{asset.assetDetails.status}</Td>
                    <Td>{asset.assetDetails.lastSeen}</Td>
                  </tr>
                ))}
              </tbody>
            </ReportTable>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <IconButton onClick={downloadCSV} sx={{ color: 'white' }}>
                <GetApp />
              </IconButton>
              <IconButton onClick={printReport} sx={{ color: 'white' }}>
                <Print />
              </IconButton>
            </Box>
          </>
        )}
      </MainContent>
    </ReportsRoot>
  );
};

export default Reports;