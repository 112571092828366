import React, { useState } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { Box, Typography, Toolbar, IconButton, Select, MenuItem, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StarIcon from '@mui/icons-material/Star';
import EmailIcon from '@mui/icons-material/Email';
import SettingsIcon from '@mui/icons-material/Settings';
import { ExitToApp, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { auditLogsData } from './staticAuditLogsData';
import { useNavigate } from 'react-router-dom';
import SFSLogo from './images/SecureFlowSense_logo_nobg.png';

const AuditLogsRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #111315;
  display: flex;
  color: #fff;
  font-family: 'Inter', sans-serif;
`;

const Sidebar = muiStyled(Box)(({ theme }) => ({
  width: 210,
  backgroundColor: '#1A1D1F',
  padding: theme.spacing(2),
}));

const MainContent = styled.main`
  flex: 1;
  padding: 24px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1px;
  margin-top: 24px;
`;

const Th = styled.th`
  text-align: left;
  padding: 16px;
  background-color: #1a1d1f;
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const Td = styled.td`
  padding: 16px;
  background-color: #1a1d1f;
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

const StyledSelect = styled(Select)`
  background-color: #1a1d1f;
  color: #fff;
  .MuiSelect-icon {
    color: #fff;
  }
`;

const AuditLogs = () => {
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const sidebarItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: '/home' },
    { text: 'Assets', icon: <InventoryIcon />, link: '/assets' },
    { text: 'Reports', icon: <AssessmentIcon />, link: '/reports' },
    { text: 'Request List', icon: <StarIcon />, link: '/request-list' },
    { text: 'Audit Logs', icon: <EmailIcon />, active: true, link: '/audit-logs' },
    { text: 'Settings', icon: <SettingsIcon />, link: '/settings' },
  ];

  const handleSidebarItemClick = (item) => {
    if (item.link) {
      navigate(item.link);
    }
  };

  const handleLogout = () => {
    navigate('/login');
  };

  return (
    <AuditLogsRoot>
      <Sidebar>
        <Box sx={{ width: '200px', marginBottom: '24px' }}>
          <img src={SFSLogo} alt="SecureFlowSense" style={{ width: '100%' }} />
        </Box>
        <List>
          {sidebarItems.map((item) => (
            <ListItem 
              button 
              key={item.text}
              onClick={() => handleSidebarItemClick(item)}
              sx={{ 
                color: item.active ? '#FF7E00' : 'inherit',
                '&:hover': { backgroundColor: 'rgba(255, 126, 0, 0.1)' },
              }}
            >
              <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Sidebar>
      <MainContent>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Typography variant="h4" sx={{ mb: 3 }}>Audit Logs</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" component="span" sx={{ mr: 2, color: 'white' }}>
              Admin
            </Typography>
            <IconButton color="inherit" sx={{ color: 'white' }} onClick={handleLogout}>
              <ExitToApp />
            </IconButton>
          </Box>
        </Toolbar>

        <Table>
          <thead>
            <tr>
              <Th>Type</Th>
              <Th>Details</Th>
              <Th>Performed By</Th>
              <Th>Organization</Th>
              <Th>Department</Th>
              <Th>Date</Th>
            </tr>
          </thead>
          <tbody>
            {auditLogsData.logs.map((log, index) => (
              <tr key={index}>
                <Td>{log.type}</Td>
                <Td>{log.details}</Td>
                <Td>{log.performedBy}</Td>
                <Td>{log.organization}</Td>
                <Td>{log.department}</Td>
                <Td>{log.date}</Td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination>
          <div>
            <span>Items per page: </span>
            <StyledSelect
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
              sx={{ width: 70 }}
            >
              <MenuItem value={5}>10</MenuItem>
              <MenuItem value={10}>20</MenuItem>
              <MenuItem value={20}>30</MenuItem>
            </StyledSelect>
            <span> 1-10 of 3 items</span>
          </div>
          <div>
            <span>1 of 1 pages</span>
            <IconButton
              onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
              disabled={currentPage === 1}
              sx={{ color: 'white' }}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
              onClick={() => setCurrentPage(prev => Math.min(auditLogsData.totalPages, prev + 1))}
              disabled={currentPage === auditLogsData.totalPages}
              sx={{ color: 'white' }}
            >
              <ChevronRight />
            </IconButton>
          </div>
        </Pagination>
      </MainContent>
    </AuditLogsRoot>
  );
};

export default AuditLogs;