export const requestListData = {
  requests: [
    {
      requestId: "AR001",
      requestedAssets: "Laptop",
      organizationRequestedBy: "FGV",
      branchRequestBy: "HQ",
      remarks: "-",
      status: "Pending"
    },
    {
      requestId: "AR002",
      requestedAssets: "Laptop",
      organizationRequestedBy: "FELDA",
      branchRequestBy: "HQ",
      remarks: "-",
      status: "Pending"
    },
    {
      requestId: "AR003",
      requestedAssets: "Mobile Phone",
      organizationRequestedBy: "FGV",
      branchRequestBy: "Johor",
      remarks: "Please give the spec of the server",
      status: "Rejected"
    },
    {
      requestId: "AR004",
      requestedAssets: "HPE Server",
      organizationRequestedBy: "FGV",
      branchRequestBy: "Selangor",
      remarks: "Giving at 23/05/2024",
      status: "Approved"
    },
    {
      requestId: "AR005",
      requestedAssets: "Laptop",
      organizationRequestedBy: "FELDA",
      branchRequestBy: "Sabah",
      remarks: "Buying",
      status: "In Progress"
    }
  ],
  totalItems: 5,
  totalPages: 1,
  formData: {
    organization: ["FGV", "FELDA"],
    branch: ["HQ", "Johor", "Sabah", "Selangor"],
    categories: ["Hardware", "Software", "Network"],
    assetTypes: ["Laptop", "Mobile Phone", "Server", "Desktop"],
    brands: ["HP", "Dell", "Lenovo", "Apple"],
    departments: ["IT", "HR", "Finance", "Operations"]
  }
};