import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Container, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Person, Lock, Email } from '@mui/icons-material';
import SFSLogo from './images/SecureFlowSense_logo_nobg.png';
import prodataImage from './images/ProdataImage.png';
import backgroundImage from './images/Background.png';
import ReCAPTCHA from "react-google-recaptcha";

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const FormContainer = styled(Box)({
  width: '100%',
  maxWidth: '300px',
  padding: '40px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '8px',
});

const StyledTextField = styled(TextField)({
  marginBottom: '16px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'black',
  },
  '& .MuiInputBase-input': {
    color: 'black',
  },
});

const StyledButton = styled(Button)({
  backgroundColor: '#C76D16',
  color: 'white',
  '&:hover': {
    backgroundColor: '#f57c00',
  },
  width: '100%',
  height: '48px',
  borderRadius: '4px',
  fontSize: '16px',
  fontWeight: 500,
  marginTop: '16px',
});

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorPasswordMessage, setErrorPasswordMessage] = useState('');
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (username === 'admin' && password === 'P@ssw0rd') {
      navigate('/home');
    } else {
      setErrorMessage('Invalid username or password');
    }

    /*try {
      localStorage.removeItem('token');
      const response = await callApi('post', `${process.env.REACT_APP_API_BASE_URL}/auth/login`, { username, password });
      
      if (response.status === 'success' && response.token) {
        localStorage.setItem('token', response.token);
        login(username);
        navigate('/home');
      } else {
        setErrorMessage(response.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage('Login failed. Please try again.');
    }*/
  };

  const handleForgotPasswordClick = () => {
    setOpenForgotPassword(true);
  };

  const handleCloseForgotPassword = () => {
    setOpenForgotPassword(false);
    setEmail('');
    setCaptchaValue(null);
    setErrorPasswordMessage('');
  };

  const handleForgotPasswordSubmit = () => {
    if (email && captchaValue) {
      // TODO: Implement password reset logic here
      console.log('Password reset requested for:', email);
      handleCloseForgotPassword();
    } else {
      setErrorPasswordMessage('Please fill in all fields and complete the captcha.');
    }
  };

  return (
    <StyledContainer maxWidth={false} disableGutters>
      <FormContainer>
        <Box sx={{ width: '400px', marginTop: '10px', marginBottom: '2px' }}>
          <img src={SFSLogo} alt="SecureFlowSense" style={{ width: '100%' }} />
        </Box>
        <Box sx={{ width: '300px', marginBottom: '10px' }}>
          <img src={prodataImage} alt="PRODATA logo" style={{ width: '100%' }} />
        </Box>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <StyledTextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person sx={{ color: 'black' }} />
                </InputAdornment>
              ),
            }}
          />
          <StyledTextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock sx={{ color: 'black' }} />
                </InputAdornment>
              ),
            }}
          />
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
          >
            LOGIN
          </StyledButton>
          {errorMessage && (
            <Typography color="error" component="p">
              {errorMessage}
            </Typography>
          )}
        </form>
        <Typography 
          variant="body2" 
          sx={{ mt: 2, color: 'black', cursor: 'pointer' }}
          onClick={handleForgotPasswordClick}
        >
          Forgot password?
        </Typography>
      </FormContainer>

      <Dialog open={openForgotPassword} onClose={handleCloseForgotPassword}>
        <DialogTitle>Forgot Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ mt: 2 }}>
            <ReCAPTCHA
              sitekey="6Lc2dUsqAAAAACvpRdop6WaEyKdBZ_N57P7EWcEZ"
              onChange={(value) => setCaptchaValue(value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForgotPassword}>Cancel</Button>
          <Button onClick={handleForgotPasswordSubmit}>Submit</Button>
        </DialogActions>

        {errorPasswordMessage && (
            <Typography color="error" component="p">
              {errorPasswordMessage}
            </Typography>
          )}
      </Dialog>
    </StyledContainer>
  );
};

export default Login;