import React, { useState } from 'react';
import {
  Box, Grid, Typography, List, ListItem, ListItemIcon, ListItemText,
  Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Toolbar,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CardContent
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StarIcon from '@mui/icons-material/Star';
import EmailIcon from '@mui/icons-material/Email';
import SettingsIcon from '@mui/icons-material/Settings';
import { motion } from 'framer-motion';
import { StatCard, CategoryPieChart, AssetLocationChart, ListCard } from './DashboardWidgets';
import { ExitToApp } from '@mui/icons-material';
import { dashboardData } from './staticDashboardData';
import { useNavigate } from 'react-router-dom';
import SFSLogo from './images/SecureFlowSense_logo_nobg.png';

const darkTheme = {
  backgroundColor: '#1A1D1F',
  textColor: 'white',
  borderColor: '#F6983D',
};

const DashboardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: '#111315',
  color: '#FFFFFF',
  minHeight: '100vh',
}));

const Sidebar = styled(Box)(({ theme }) => ({
  width: 265,
  backgroundColor: '#1A1D1F',
  padding: theme.spacing(2),
}));

const MainContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const AnimatedGrid = motion(Grid);

const Home = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const handleSidebarItemClick = (item) => {
    if (item.link) {
      navigate(item.link);
    }
  };

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const sidebarItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, active: true, link: '/home' },
    { text: 'Assets', icon: <InventoryIcon />, link: '/assets' },
    { text: 'Reports', icon: <AssessmentIcon />, link: '/reports' },
    { text: 'Request List', icon: <StarIcon />, link: '/request-list' },
    { text: 'Audit Logs', icon: <EmailIcon />, link: '/audit-logs' },
    { text: 'Settings', icon: <SettingsIcon />, link: '/settings' },
  ];

  if (!dashboardData) {
    return <Typography>Loading...</Typography>;
  }

  const handleLogout = () => {
    navigate('/login');
  };

  const renderDialogContent = () => {
    switch (selectedItem) {
      case 'Organization':
        return (
          <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell sx={{ color: 'white', borderBottom: '2px solid #F6983D' }}>Organization</TableCell>
                <TableCell sx={{ color: 'white', borderBottom: '2px solid #F6983D' }}>Total Assets</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ color: 'white', borderBottom: 'none' }}>FGV</TableCell>
                  <TableCell align="right" sx={{ color: 'white', borderBottom: 'none' }}>{dashboardData.totalFGV}</TableCell>
                </TableRow>
                <TableRow>
                <TableCell sx={{ color: 'white', borderBottom: 'none' }}>FELDA</TableCell>
                <TableCell align="right" sx={{ color: 'white', borderBottom: 'none' }}>{dashboardData.totalFELDA}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          </CardContent>
        );
      case 'Company':
        return (
          <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell sx={{ color: 'white', borderBottom: '2px solid #F6983D' }}>Company</TableCell>
                <TableCell sx={{ color: 'white', borderBottom: '2px solid #F6983D' }}>Total Assets</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                <TableCell sx={{ color: 'white', borderBottom: 'none' }}>FGV</TableCell>
                <TableCell align="right" sx={{ color: 'white', borderBottom: 'none' }}>{dashboardData.totalFGV}</TableCell>
                </TableRow>
                <TableRow>
                <TableCell sx={{ color: 'white', borderBottom: 'none' }}>FPM (FELDA)</TableCell>
                <TableCell align="right" sx={{ color: 'white', borderBottom: 'none' }}>{dashboardData.totalFELDA}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          </CardContent>
        );
      case 'Branch':
        return (
          <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell sx={{ color: 'white', borderBottom: '2px solid #F6983D' }}>Branch</TableCell>
                <TableCell sx={{ color: 'white', borderBottom: '2px solid #F6983D' }}>Total Assets</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dashboardData.assetLocations.map((location) => (
                  <TableRow key={location.name}>
                    <TableCell sx={{ color: 'white', borderBottom: 'none' }}>{location.name}</TableCell>
                    <TableCell align="right" sx={{ color: 'white', borderBottom: 'none' }}>{location.Laptop}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </CardContent>
        );
      case 'Contracts':
        return (
          <CardContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                  <TableCell sx={{ color: 'white', borderBottom: '2px solid #F6983D' }}>Contract Company</TableCell>
                  <TableCell sx={{ color: 'white', borderBottom: '2px solid #F6983D' }}>Assets</TableCell>
                  <TableCell sx={{ color: 'white', borderBottom: '2px solid #F6983D' }}>Start Date</TableCell>
                  <TableCell sx={{ color: 'white', borderBottom: '2px solid #F6983D' }}>End date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                <TableCell sx={{ color: 'white', borderBottom: 'none', span: 4 }}>No data available</TableCell>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
          );
      default:
        return <Typography>No data available</Typography>;
    }
  };

  return (
    <DashboardContainer>
      <Sidebar>
        <Box sx={{ width: '200px', marginBottom: '24px' }}>
          <img src={SFSLogo} alt="SecureFlowSense" style={{ width: '100%' }} />
        </Box>
        <List>
          {sidebarItems.map((item) => (
            <ListItem 
              button 
              key={item.text}
              onClick={() => handleSidebarItemClick(item)}
              sx={{ 
                color: item.active ? '#FF7E00' : 'inherit',
                '&:hover': { backgroundColor: 'rgba(255, 126, 0, 0.1)' },
              }}
            >
              <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Sidebar>
      <MainContent>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Typography variant="h4" sx={{ mb: 3 }}>DASHBOARD</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" component="span" sx={{ mr: 2, color: 'white' }}>
              Admin
            </Typography>
            <IconButton color="inherit" sx={{ color: 'white' }} onClick={handleLogout}>
              <ExitToApp />
            </IconButton>
          </Box>
        </Toolbar>
        <Grid container spacing={3}>
          {['Organization', 'Company', 'Branch', 'Contracts'].map((item) => (
            <Grid item xs={3} key={item}>
              <AnimatedGrid
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => handleCardClick(item)}
              >
                <StatCard title={item} value={dashboardData[item.toLowerCase()]} />
              </AnimatedGrid>
            </Grid>
          ))}
          <Grid item xs={3}>
          <AnimatedGrid
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <CategoryPieChart 
                data={{
                  used: dashboardData.usedLicense,
                  available: dashboardData.availableLicense
                }}
                title="Total License"
                type="license"
              />
            </AnimatedGrid>
          </Grid>
          <Grid item xs={3}>
          <AnimatedGrid
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <CategoryPieChart 
                data={{
                  used: dashboardData.activeAssets,
                  available: dashboardData.inactiveAssets
                }}
                title="Total Assets"
                type="assets"
              />
            </AnimatedGrid>
          </Grid>
          <Grid item xs={3}>
            <AnimatedGrid
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <CategoryPieChart 
                data={dashboardData.topCategories} 
                title="Top 5 Category"
                type="category"
              />
            </AnimatedGrid>
          </Grid>
          <Grid item xs={3}>
            <AnimatedGrid
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.5 }}
            >
              <CategoryPieChart 
                data={dashboardData.topStatuses} 
                title="Top 5 Status"
                type="status"
              />
            </AnimatedGrid>
          </Grid>
          <Grid item xs={4}>
            <AnimatedGrid
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
            <AssetLocationChart data={dashboardData.assetLocations} />
          </AnimatedGrid>
          </Grid>
          <Grid item xs={4}>
            <AnimatedGrid
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.7 }}
            >
            <ListCard 
              title="Top 5 Installed OS" 
              data={dashboardData.topInstalledSoftware}
              columns={['Categories', 'No. of Installation']}
            />
            </AnimatedGrid>
          </Grid>
          <Grid item xs={4}>
            <AnimatedGrid
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.8 }}
            >
            <ListCard 
              title="Top 5 Critical Alert" 
              data={dashboardData.topCriticalAlerts}
              columns={['Category', 'No. of Alert']}
            />
            </AnimatedGrid>
          </Grid>
        </Grid>
      </MainContent>
      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            backgroundColor: darkTheme.backgroundColor, 
            color: darkTheme.textColor, 
            border: `1px solid ${darkTheme.borderColor}`,
            borderRadius: '24px',
            height: '40%',
            background: 'linear-gradient(90deg, #291919 0%, black 100%)',
          },
        }}
      >
        <DialogTitle>{selectedItem}</DialogTitle>
        <DialogContent>
          {renderDialogContent()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} sx={{ color: '#FF7E00' }}>Close</Button>
        </DialogActions>
      </Dialog>
    </DashboardContainer>
  );
};

export default Home;