import React, { useState } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import {
  Box, Typography, Toolbar, IconButton, Button, Select, MenuItem, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, List,
  ListItem, ListItemIcon, ListItemText, TextField, Grid, Menu
} from '@mui/material';
import {
  ExitToApp, Add, MoreHoriz, ChevronLeft, ChevronRight, Search
} from '@mui/icons-material';
import { requestListData } from './staticRequestListData';
import AddNewRequest from './AddNewRequest';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StarIcon from '@mui/icons-material/Star';
import EmailIcon from '@mui/icons-material/Email';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import SFSLogo from './images/SecureFlowSense_logo_nobg.png';

const RequestListRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #111315;
  display: flex;
  color: #fff;
  font-family: 'Inter', sans-serif;
`;

const Sidebar = muiStyled(Box)(({ theme }) => ({
  width: 210,
  backgroundColor: '#1A1D1F',
  padding: theme.spacing(2),
}));

const MainContent = styled.main`
  flex: 1;
  padding: 24px;
`;

const NewRequestButton = styled(Button)`
  background-color: #FF7E00;
  color: #fff;
  &:hover {
    background-color: #E67100;
  }
`;

const StyledTableContainer = muiStyled(TableContainer)`
  margin-top: 24px;
  background-color: #1A1D1F;
`;

const StyledTableCell = muiStyled(TableCell)`
  color: #fff;
  border-bottom: 1px solid #2A2D2F;
`;

const StyledTableRow = muiStyled(TableRow)`
  &:last-child td, &:last-child th {
    border-bottom: 0;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

const FormContainer = styled(Paper)`
  background-color: #1A1D1F;
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 24px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  background-color: #111315;
  color: #fff;
  .MuiSelect-icon {
    color: #fff;
  }
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  background-color: #111315;
  color: #fff;
  .MuiInputBase-input {
    color: #fff;
  }
  .MuiInputLabel-root {
    color: #fff;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #fff;
  }
`;

const SearchButton = styled(Button)`
  background-color: #FF7E00;
  color: #fff;
  &:hover {
    background-color: #E67100;
  }
`;

const ClearButton = styled(Button)`
  background-color: #555;
  color: #fff;
  margin-left: 10px;
  &:hover {
    background-color: #777;
  }
`;

const RequestList = () => {
  const [showAddNewRequest, setShowAddNewRequest] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useState({
    requestId: '',
    requestedAssets: '',
    organizationRequestedBy: '',
    branchRequestBy: '',
    status: '',
  });
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAnchorClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const handleNewRequest = () => {
    setShowAddNewRequest(true);
  };

  const handleCloseNewRequest = () => {
    setShowAddNewRequest(false);
  };

  const StatusBadge = styled.span`
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  background-color: ${props => 
    props.status === 'Pending' ? '#FFA500' :
    props.status === 'Rejected' ? '#f10000' :
    props.status === 'Approved' ? '#0dfc08' :
    props.status === 'In Progress' ? '#FFFF00' : '#ccc'};
  color: #fff;
`;

  const sidebarItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: '/home' },
    { text: 'Assets', icon: <InventoryIcon />, link: '/assets' },
    { text: 'Reports', icon: <AssessmentIcon />, link: '/reports' },
    { text: 'Request List', icon: <StarIcon />, active: true, link: '/request-list' },
    { text: 'Audit Logs', icon: <EmailIcon />, link: '/audit-logs' },
    { text: 'Settings', icon: <SettingsIcon />, link: '/settings' },
  ];

  const handleSidebarItemClick = (item) => {
    if (item.link) {
      navigate(item.link);
    }
  };

  const handleLogout = () => {
    navigate('/login');
  };

  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    setSearchParams(prevParams => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    // Implement search functionality here
    console.log('Search params:', searchParams);
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setSearchParams({
      requestId: '',
      requestedAssets: '',
      regionRequestedBy: '',
      departmentRequestBy: '',
      status: '',
    });
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = requestListData.requests.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <RequestListRoot>
      <Sidebar>
        <Box sx={{ width: '200px', marginBottom: '24px' }}>
          <img src={SFSLogo} alt="SecureFlowSense" style={{ width: '100%' }} />
        </Box>
        <List>
          {sidebarItems.map((item) => (
            <ListItem 
              button 
              key={item.text}
              onClick={() => handleSidebarItemClick(item)}
              sx={{ 
                color: item.active ? '#FF7E00' : 'inherit',
                '&:hover': { backgroundColor: 'rgba(255, 126, 0, 0.1)' },
              }}
            >
              <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Sidebar>
      <MainContent>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Typography variant="h4" sx={{ mb: 3 }}>REQUEST LIST</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" component="span" sx={{ mr: 2, color: 'white' }}>
              Admin
            </Typography>
            <IconButton color="inherit" sx={{ color: 'white' }} onClick={handleLogout}>
              <ExitToApp />
            </IconButton>
          </Box>
        </Toolbar>

        <FormContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <StyledTextField
                label="Request ID"
                name="requestId"
                value={searchParams.requestId}
                onChange={handleSearchChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledTextField
                label="Requested Assets"
                name="requestedAssets"
                value={searchParams.requestedAssets}
                onChange={handleSearchChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledSelect
                value={searchParams.organizationRequestedBy}
                onChange={handleSearchChange}
                displayEmpty
                name="organizationRequestedBy"
              >
                <MenuItem value="">Select Organization</MenuItem>
                <MenuItem value="FGV">FGV</MenuItem>
                <MenuItem value="FELDA">FELDA</MenuItem>
              </StyledSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledSelect
                value={searchParams.branchRequestBy}
                onChange={handleSearchChange}
                displayEmpty
                name="branchRequestBy"
              >
                <MenuItem value="">Select Branch</MenuItem>
                <MenuItem value="HQ">HQ</MenuItem>
                <MenuItem value="Johor">Johor</MenuItem>
                <MenuItem value="Sabah">Sabah</MenuItem>
              </StyledSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledSelect
                value={searchParams.status}
                onChange={handleSearchChange}
                displayEmpty
                name="status"
              >
                <MenuItem value="">Select Status</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
                <MenuItem value="In Progress">In Progress</MenuItem>
              </StyledSelect>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <SearchButton onClick={handleSearch} startIcon={<Search />}>
              Search
            </SearchButton>
            <ClearButton onClick={clearFilters}>Clear</ClearButton>
          </Box>
        </FormContainer>

        <NewRequestButton startIcon={<Add />} onClick={handleNewRequest}>
          NEW REQUEST
        </NewRequestButton>

        <StyledTableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Request ID</StyledTableCell>
                <StyledTableCell>Requested Assets</StyledTableCell>
                <StyledTableCell>Organization Requested By</StyledTableCell>
                <StyledTableCell>Branch Request By</StyledTableCell>
                <StyledTableCell>Remarks</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentItems.map((row) => (
                <StyledTableRow key={row.requestId}>
                  <StyledTableCell>{row.requestId}</StyledTableCell>
                  <StyledTableCell>{row.requestedAssets}</StyledTableCell>
                  <StyledTableCell>{row.organizationRequestedBy}</StyledTableCell>
                  <StyledTableCell>{row.branchRequestBy}</StyledTableCell>
                  <StyledTableCell>{row.remarks}</StyledTableCell>
                  <StyledTableCell>
                    <StatusBadge status={row.status}>{row.status}</StatusBadge>
                  </StyledTableCell>
                  <StyledTableCell>
                  <IconButton sx={{ color: 'white' }} onClick={handleAnchorClick}>
                    <MoreHoriz />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleAnchorClose}
                  >
                    <MenuItem onClick={() => handleAnchorClick('Approve')}>Approve</MenuItem>
                    <MenuItem onClick={() => handleAnchorClick('Reject')}>Reject</MenuItem>
                    <MenuItem onClick={() => handleAnchorClick('In Progress')}>In Progress</MenuItem>
                  </Menu>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>

        <PaginationContainer>
          <div>
            Items per page: 
            <Select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(e.target.value)}
              sx={{ color: 'white', marginLeft: 1 }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
            </Select>
            <Typography component="span" sx={{ ml: 2 }}>
              {indexOfFirstItem + 1}-{Math.min(indexOfLastItem, requestListData.requests.length)} of {requestListData.requests.length} items
            </Typography>
          </div>
          <div>
            <Typography component="span" sx={{ mr: 2 }}>
              {currentPage} of {Math.ceil(requestListData.requests.length / itemsPerPage)} pages
            </Typography>
            <IconButton 
              sx={{ color: 'white' }}
              onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
              disabled={currentPage === 1}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton 
              sx={{ color: 'white' }}
              onClick={() => setCurrentPage(prev => Math.min(Math.ceil(requestListData.requests.length / itemsPerPage), prev + 1))}
              disabled={currentPage === Math.ceil(requestListData.requests.length / itemsPerPage)}
            >
              <ChevronRight />
            </IconButton>
          </div>
        </PaginationContainer>

        {showAddNewRequest && (
          <AddNewRequest onClose={handleCloseNewRequest} />
        )}
      </MainContent>
    </RequestListRoot>
  );
};

export default RequestList;