import React from 'react';
import styled from 'styled-components';
import { Button, TextField } from '@mui/material';

const SectionContainer = styled.div`
  background-color: #1A1D1F;
  padding: 24px;
  border-radius: 8px;
  margin-top: 24px;
`;

const InfoRow = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const InfoLabel = styled.span`
  width: 150px;
  font-weight: bold;
`;

const InfoValue = styled.span`
  flex: 1;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  margin-top: 16px;
  .MuiInputBase-root {
    color: #fff;
    background-color: #111315;
  }
  .MuiInputLabel-root {
    color: #fff;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 16px;
`;

const StyledButton = styled(Button)`
  text-transform: none;
`;

const LicenseSection = ({ license }) => {
  return (
    <SectionContainer>
      <InfoRow>
        <InfoLabel>License Name</InfoLabel>
        <InfoValue>: {license.name}</InfoValue>
      </InfoRow>
      <InfoRow>
        <InfoLabel>License Type</InfoLabel>
        <InfoValue>: {license.type}</InfoValue>
      </InfoRow>
      <InfoRow>
        <InfoLabel>Start Date</InfoLabel>
        <InfoValue>: {license.startDate}</InfoValue>
      </InfoRow>
      <InfoRow>
        <InfoLabel>End Date</InfoLabel>
        <InfoValue>: {license.endDate}</InfoValue>
      </InfoRow>
      <InfoRow>
        <InfoLabel>Upload Date</InfoLabel>
        <InfoValue>: {license.uploadDate}</InfoValue>
      </InfoRow>
      <InfoRow>
        <InfoLabel>Total License</InfoLabel>
        <InfoValue>: {license.totalLicense}</InfoValue>
      </InfoRow>
      <InfoRow>
        <InfoLabel>Status</InfoLabel>
        <InfoValue>: {license.status}</InfoValue>
      </InfoRow>
      <StyledTextField label="License File" variant="outlined" />
      <ButtonContainer>
        <StyledButton variant="contained" color="primary">
          UPLOAD LICENSE FILE
        </StyledButton>
        <StyledButton variant="contained" style={{ backgroundColor: '#FF7E00', color: '#fff' }}>
          Activate
        </StyledButton>
      </ButtonContainer>
    </SectionContainer>
  );
};

export default LicenseSection;