import React, { useEffect, useRef, useState } from 'react';

const GoogleMap = ({ center, zoom, markers }) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const markersRef = useRef([]);
  const scriptLoadedRef = useRef(false);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (!scriptLoadedRef.current) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAh-ApxMSt0D7P0uoJALXahYcPdYOdAHKY&libraries=marker&v=beta`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
        script.onload = initMap;
        scriptLoadedRef.current = true;
      } else {
        initMap();
      }
    };

    const initMap = () => {
      if (!map && mapRef.current && window.google && window.google.maps) {
        const newMap = new window.google.maps.Map(mapRef.current, { center, zoom });
        setMap(newMap);
      }
    };

    if (window.google && window.google.maps) {
      initMap();
    } else {
      loadGoogleMapsScript();
    }

    return () => {
      if (scriptLoadedRef.current) {
        const script = document.querySelector(`script[src^="https://maps.googleapis.com/maps/api/js"]`);
        if (script) {
          document.head.removeChild(script);
        }
        scriptLoadedRef.current = false;
      }
    };
  }, []);

  useEffect(() => {
    if (map) {
      map.setCenter(center);
      map.setZoom(zoom);
    }
  }, [map, center, zoom]);

  useEffect(() => {
    if (map) {
      // Clear existing markers
      markersRef.current.forEach(marker => marker.setMap(null));
      markersRef.current = [];

      // Add new markers
      markers.forEach(markerData => {
        const marker = new window.google.maps.Marker({
          position: { lat: markerData.latitude, lng: markerData.longitude },
          map: map,
          title: markerData.location,
        });

        const infoWindow = new window.google.maps.InfoWindow({
          content: `<div><strong>${markerData.location}</strong><br>Last seen: ${markerData.lastSeen}</div>`,
        });

        marker.addListener('click', () => {
          infoWindow.open(map, marker);
        });

        markersRef.current.push(marker);
      });
    }
  }, [map, markers]);

  return <div ref={mapRef} style={{ height: '100%', width: '100%' }} />;
};

export default GoogleMap;