import React, { useState } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { Box, Typography, Toolbar, IconButton, Tabs, Tab, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StarIcon from '@mui/icons-material/Star';
import EmailIcon from '@mui/icons-material/Email';
import SettingsIcon from '@mui/icons-material/Settings';
import { ExitToApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { settingsData } from './staticSettingsData';
import LicenseSection from './LicenseSection';
import SMTPConfigSection from './SMTPConfigSection';
import UserListSection from './UserListSection';
import SFSLogo from './images/SecureFlowSense_logo_nobg.png';

const SettingsRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #111315;
  display: flex;
  color: #fff;
  font-family: 'Inter', sans-serif;
`;

const Sidebar = muiStyled(Box)(({ theme }) => ({
  width: 210,
  backgroundColor: '#1A1D1F',
  padding: theme.spacing(2),
}));

const MainContent = styled.main`
  flex: 1;
  padding: 24px;
`;

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #FF7E00;
  }
`;

const StyledTab = styled(Tab)`
  color: #fff !important;
  &.Mui-selected {
    color: #FF7E00 !important;
  }
`;

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const sidebarItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: '/home' },
    { text: 'Assets', icon: <InventoryIcon />, link: '/assets' },
    { text: 'Reports', icon: <AssessmentIcon />, link: '/reports' },
    { text: 'Request List', icon: <StarIcon />, link: '/request-list' },
    { text: 'Audit Logs', icon: <EmailIcon />, link: '/audit-logs' },
    { text: 'Settings', icon: <SettingsIcon />, active: true, link: '/settings' },
  ];

  const handleSidebarItemClick = (item) => {
    if (item.link) {
      navigate(item.link);
    }
  };

  const handleLogout = () => {
    navigate('/login');
  };

  return (
    <SettingsRoot>
      <Sidebar>
        <Box sx={{ width: '200px', marginBottom: '24px' }}>
          <img src={SFSLogo} alt="SecureFlowSense" style={{ width: '100%' }} />
        </Box>
        <List>
          {sidebarItems.map((item) => (
            <ListItem 
              button 
              key={item.text}
              onClick={() => handleSidebarItemClick(item)}
              sx={{ 
                color: item.active ? '#FF7E00' : 'inherit',
                '&:hover': { backgroundColor: 'rgba(255, 126, 0, 0.1)' },
              }}
            >
              <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Sidebar>
      <MainContent>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Typography variant="h4" sx={{ mb: 3 }}>SETTINGS</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" component="span" sx={{ mr: 2, color: 'white' }}>
              Admin
            </Typography>
            <IconButton color="inherit" sx={{ color: 'white' }} onClick={handleLogout}>
              <ExitToApp />
            </IconButton>
          </Box>
        </Toolbar>

        <StyledTabs value={activeTab} onChange={handleTabChange}>
          <StyledTab label="License" />
          <StyledTab label="SMTP Configuration" />
          <StyledTab label="User List" />
        </StyledTabs>

        {activeTab === 0 && <LicenseSection license={settingsData.license} />}
        {activeTab === 1 && <SMTPConfigSection />}
        {activeTab === 2 && <UserListSection users={settingsData.users} totalUsers={settingsData.totalUsers} totalPages={settingsData.totalPages} />}
      </MainContent>
    </SettingsRoot>
  );
};

export default Settings;