export const dashboardData = {
    organization: 2, // FGV and FELDA
    company: 2, // FGV and FPM (FELDA)
    branch: 3, // HQ, Sabah, and "Johor, Pasir Gudang"
    contracts: 0, // Keeping this as is since there's no new information
    totalLicense: 100, // Total unique laptops in the data
    usedLicense: 57, // Assuming all laptops are in use
    availableLicense: 43, // No information about unused licenses
    totalAssets: 57,
    activeAssets: 57, // Assuming all are active
    inactiveAssets: 0,
	totalFGV: 54,
	totalFELDA: 3,
    topCategories: [
      { name: "Laptop", value: 57 },
    ],
    topStatuses: [
      { name: "Repair", value: 0 },
      { name: "Return", value: 0 },
      { name: "Lost", value: 0 }
    ],

	assetLocations: [
      { name: "KL", Laptop: 43 },
      { name: "Sabah", Laptop: 7 },
      { name: "Johor", Laptop: 4 }
    ],
    topInstalledSoftware: [
      { name: "Microsoft Windows 11", value: 37 },
      { name: "Microsoft Windows 10", value: 20 },
      { name: "" },
      { name: "" }
    ],
    topCriticalAlerts: [
      { name: "Uninstall Attempts", value: 0 },
      { name: "Stop Service Attempts", value: 0 },
      { name: "" },
      { name: "" }
    ]
  };