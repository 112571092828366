import React, { useState } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import {
  TextField, IconButton, Box, List, ListItem, ListItemIcon, ListItemText, Typography, Toolbar,
  MenuItem, Checkbox, Button, Select, Grid, Paper, Menu, OutlinedInput
} from '@mui/material';
import { ExitToApp, MoreHoriz, Search } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StarIcon from '@mui/icons-material/Star';
import EmailIcon from '@mui/icons-material/Email';
import SettingsIcon from '@mui/icons-material/Settings';
import { assetsData } from './staticAssetsData';
import { useNavigate } from 'react-router-dom';
import SFSLogo from './images/SecureFlowSense_logo_nobg.png';

const AssetsRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: '#111315',
  color: '#FFFFFF',
  minHeight: '100vh',
}));

const Sidebar = muiStyled(Box)(({ theme }) => ({
  width: 210,
  backgroundColor: '#1A1D1F',
  padding: theme.spacing(2),
}));

const MainContent = styled.main`
  flex: 1;
  padding: 24px;
`;

const FormContainer = styled(Paper)`
  background-color: #1A1D1F;
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 24px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  background-color: #111315;
  color: #fff;
  .MuiSelect-icon {
    color: #fff;
  }
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  background-color: #111315;
  color: #fff;
  .MuiInputBase-input {
    color: #fff;
  }
  .MuiInputLabel-root {
    color: #fff;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #fff;
  }
`;

const SearchButton = styled(Button)`
  background-color: #FF7E00;
  color: #fff;
  &:hover {
    background-color: #E67100;
  }
`;

const ClearButton = styled(Button)`
  background-color: #555;
  color: #fff;
  margin-left: 10px;
  &:hover {
    background-color: #777;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1px;
`;

const Th = styled.th`
  text-align: left;
  padding: 16px;
  background-color: #1a1d1f;
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const Td = styled.td`
  padding: 16px;
  background-color: #1a1d1f;
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const StatusBadge = styled.span`
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  background-color: ${props => 
    props.status === 'Active' ? '#0dfc08' :
    props.status === 'Stolen' ? '#f10000' :
    props.status === 'Repair' ? '#ff7e00' : '#ccc'};
  color: #fff;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

const Assets = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAnchorClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const [searchParams, setSearchParams] = useState({
    assetNumber: '',
    serialNumber: '',
    organization: [],
    company: [],
    branch: [],
  });

  const [filteredItems, setFilteredItems] = useState(assetsData.items);

  const handleSearchChange = (event) => {
    const { name, value } = event.target;
    setSearchParams(prevParams => ({
      ...prevParams,
      [name]: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleSearch = () => {
    const filtered = assetsData.items.filter(item => 
      (!searchParams.assetNumber || item.assetsNumber.includes(searchParams.assetNumber)) &&
      (!searchParams.serialNumber || item.serialNumber.includes(searchParams.serialNumber)) &&
      (searchParams.organization.length === 0 || searchParams.organization.includes(item.organization)) &&
      (searchParams.company.length === 0 || searchParams.company.includes(item.company)) &&
      (searchParams.branch.length === 0 || searchParams.branch.includes(item.branch))
    );
    setFilteredItems(filtered);
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setSearchParams({
      assetNumber: '',
      serialNumber: '',
      organization: [],
      company: [],
      branch: [],
    });
    setFilteredItems(assetsData.items);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const sidebarItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: '/home' },
    { text: 'Assets', icon: <InventoryIcon />, active: true, link: '/assets' },
    { text: 'Reports', icon: <AssessmentIcon />, link: '/reports' },
    { text: 'Request List', icon: <StarIcon />, link: '/request-list' },
    { text: 'Audit Logs', icon: <EmailIcon />, link: '/audit-logs' },
    { text: 'Settings', icon: <SettingsIcon />, link: '/settings' },
  ];

  const handleSidebarItemClick = (item) => {
    if (item.link) {
      navigate(item.link);
    }
  };

  const handleAssetClick = (assetsNumber) => {
    navigate(`/assets/${assetsNumber}`);
  };

  const handleLogout = () => {
    navigate('/login');
  };

  return (
    <AssetsRoot>
      <Sidebar>
        <Box sx={{ width: '200px', marginBottom: '24px' }}>
          <img src={SFSLogo} alt="SecureFlowSense" style={{ width: '100%' }} />
        </Box>
        <List>
          {sidebarItems.map((item) => (
            <ListItem 
              button 
              key={item.text}
              onClick={() => handleSidebarItemClick(item)}
              sx={{ 
                color: item.active ? '#FF7E00' : 'inherit',
                '&:hover': { backgroundColor: 'rgba(255, 126, 0, 0.1)' },
              }}
            >
              <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Sidebar>
      <MainContent>
        <Toolbar>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Typography variant="h4" sx={{ mb: 3 }}>ASSETS</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" component="span" sx={{ mr: 2, color: 'white' }}>
              Admin
            </Typography>
            <IconButton color="inherit" sx={{ color: 'white' }} onClick={handleLogout}>
              <ExitToApp />
            </IconButton>
          </Box>
        </Toolbar>

        <FormContainer>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
              <StyledSelect
                multiple
                value={searchParams.organization}
                onChange={handleSearchChange}
                input={<OutlinedInput />}
                renderValue={(selected) => selected.length === 0 ? 'Select Organization' : selected.join(', ')}                displayEmpty
                fullWidth
                name="organization"
              >
                <MenuItem disabled value="">
                  <em>Organization</em>
                </MenuItem>
                {['FGV', 'FELDA'].map((org) => (
                  <MenuItem key={org} value={org}>
                    <Checkbox checked={searchParams.organization.indexOf(org) > -1} />
                    <ListItemText primary={org} />
                  </MenuItem>
                ))}
              </StyledSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledSelect
                multiple
                value={searchParams.company}
                onChange={handleSearchChange}
                input={<OutlinedInput />}
                renderValue={(selected) => selected.length === 0 ? 'Select Company' : selected.join(', ')}
                displayEmpty
                fullWidth
                name="company"
              >
                <MenuItem disabled value="">
                  <em>Company</em>
                </MenuItem>
                {['FPM'].map((comp) => (
                  <MenuItem key={comp} value={comp}>
                    <Checkbox checked={searchParams.company.indexOf(comp) > -1} />
                    <ListItemText primary={comp} />
                  </MenuItem>
                ))}
              </StyledSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledSelect
                multiple
                value={searchParams.branch}
                onChange={handleSearchChange}
                input={<OutlinedInput />}
                renderValue={(selected) => selected.length === 0 ? 'Select Branch' : selected.join(', ')}                
                displayEmpty
                fullWidth
                name="branch"
              >
                <MenuItem disabled value="">
                  <em>Branch</em>
                </MenuItem>
                {['HQ', 'Johor', 'Sabah'].map((branch) => (
                  <MenuItem key={branch} value={branch}>
                    <Checkbox checked={searchParams.branch.indexOf(branch) > -1} />
                    <ListItemText primary={branch} />
                  </MenuItem>
                ))}
              </StyledSelect>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledTextField
                label="Asset Number"
                value={searchParams.assetNumber}
                onChange={handleSearchChange}
                name="assetNumber"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StyledTextField
                label="Serial Number"
                value={searchParams.serialNumber}
                onChange={handleSearchChange}
                name="serialNumber"
              />
            </Grid>
            <Grid item xs={12}>
              <SearchButton onClick={handleSearch}>
                <Search />
                Search
              </SearchButton>
              <ClearButton onClick={clearFilters}>Clear</ClearButton>
            </Grid>
          </Grid>
        </FormContainer>

        <Table>
          <thead>
            <tr>
              <Th>Asset Number</Th>
              <Th>Serial Number</Th>
              <Th>Organization</Th>
              <Th>Company</Th>
              <Th>Branch</Th>
              <Th>Asset Type</Th>
              <Th>Preventive Maintenance</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={index}>
                <Td onClick={() => handleAssetClick(item.assetsNumber)} style={{ cursor: 'pointer', color: 'orange' }}>{item.assetsNumber}</Td>
                <Td>{item.serialNumber}</Td>
                <Td>{item.organization}</Td>
                <Td>{item.company}</Td>
                <Td>{item.branch}</Td>
                <Td>{item.assetType}</Td>
                <Td><Checkbox sx={{ color: 'white' }} /></Td>
                <Td>
                  <StatusBadge status={item.status}>
                    {item.status}
                  </StatusBadge>
                </Td>
                <Td>
                  <IconButton sx={{ color: 'white' }} onClick={handleAnchorClick}>
                    <MoreHoriz />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleAnchorClose}
                  >
                    <MenuItem onClick={() => handleAnchorClick('Repair')}>Repair</MenuItem>
                    <MenuItem onClick={() => handleAnchorClick('Return')}>Return</MenuItem>
                    <MenuItem onClick={() => handleAnchorClick('Lost')}>Lost</MenuItem>
                  </Menu>
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination>
          <div>
            <span>Items per page: </span>
            <Select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
              sx={{ backgroundColor: '#fff', color: '#000' }}
            >
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            <span> {indexOfFirstItem + 1}-{indexOfLastItem} of {filteredItems.length} items</span>
          </div>
          <div>
            <Select
              value={currentPage}
              onChange={(e) => setCurrentPage(Number(e.target.value))}
              sx={{ backgroundColor: '#fff', color: '#000' }}
            >
              {[...Array(Math.ceil(filteredItems.length / itemsPerPage))].map((_, i) => (
                <MenuItem key={i} value={i + 1}>{i + 1}</MenuItem>
              ))}
            </Select>
            <span> of {Math.ceil(filteredItems.length / itemsPerPage)} pages</span>
            <IconButton
              onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
              disabled={currentPage === 1}
              sx={{ color: 'white' }}
            >
              {'<'}
            </IconButton>
            <IconButton
              onClick={() => setCurrentPage(prev => Math.min(Math.ceil(filteredItems.length / itemsPerPage), prev + 1))}
              disabled={currentPage === Math.ceil(filteredItems.length / itemsPerPage)}
              sx={{ color: 'white' }}
            >
              {'>'}
            </IconButton>
          </div>
        </Pagination>
      </MainContent>
    </AssetsRoot>
  );
};

export default Assets;