export const assetsData = {
    items: [
      {
        serialNumber: "NXVLFSM00A0351B5757600",
        assetsNumber: "PC5340006326",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVPMSM02A20701F247600",
        assetsNumber: "PC5340009205",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVLFSM00A0100307A7600",
        assetsNumber: "LPC200610066",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVPMSM02A23608D847600",
        assetsNumber: "FGV5340010875",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVCKSM01272310C583400",
        assetsNumber: "PC5340004937F",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVPMSM02A20701E3E7600",
        assetsNumber: "PC5340009993",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVCKSM012746079A53400",
        assetsNumber: "FGV5340004785",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVPMSM02A20701EF77600",
        assetsNumber: "PC5340009204",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVVESM00Y350046E07600",
        assetsNumber: "FGV5340012144",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVPMSM02A216081EB7600",
        assetsNumber: "PC5340010084",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVCKSM01274607A093400",
        assetsNumber: "PC534004056",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVPMSM01F126163F37600",
        assetsNumber: "PC5430008094",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVPMSM02A2160811E7600",
        assetsNumber: "PC5340010091",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVLFSM00A952124497600",
        assetsNumber: "LPC200602017",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVVESM00Y350046917600",
        assetsNumber: "FGV5340012052",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXB0WSM00A420150F27600",
        assetsNumber: "FGV5340012607",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVVESM00Y350046CC7600",
        assetsNumber: "FGV5340012046",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXB0WSM00A420150E17600",
        assetsNumber: "FGV5340012606",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVVESM00Y350047077600",
        assetsNumber: "FGV5340012135",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVEESM0038390721C7200",
        assetsNumber: "PC5340005246",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVPMSM02A23608D227600",
        assetsNumber: "PC5340010874",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVGTSM00792100DA47200",
        assetsNumber: "FGV5340005754",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVLUSM001010003F17600",
        assetsNumber: "FGVLPC200403013",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVPMSM01F1340334B7600",
        assetsNumber: "PC5340008355",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVLFSM0020321354F7600",
        assetsNumber: "FGV5210038473",
        organization: "FGV",
        company: "",
        branch: "Sabah",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "DTVT9SM0041200277D9600",
        assetsNumber: "FGV5210037060",
        organization: "FGV",
        company: "",
        branch: "Sabah",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXB0WSM00A420150B67600",
        assetsNumber: "FGV5340012587",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVPMSM00D05236A3B7600",
        assetsNumber: "PC5340007096",
        organization: "FGV",
        company: "",
        branch: "Johor",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "DTVWNSM00V3160046A9600",
        assetsNumber: "FGV5210236286",
        organization: "FGV",
        company: "",
        branch: "Sabah",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "DTVY3SM00A40100E4F9600",
        assetsNumber: "FGV5210237427",
        organization: "FGV",
        company: "",
        branch: "Sabah",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "UNKG3SM001334004",
        assetsNumber: "FLD5340011379",
        organization: "FELDA",
        company: "FPM",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVLFSM00A0321345F7600",
        assetsNumber: "FGV5340006342",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVLFSM00A0270C0A17600",
        assetsNumber: "LPC200819230",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "UNVPMSM06K228077",
        assetsNumber: "PC5340010276",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXB0WSM00A420150E67600",
        assetsNumber: "fld5340012630",
        organization: "FELDA",
        company: "FPM",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "DTVWNSM00V24103CBE9600",
        assetsNumber: "FLD5210235806",
        organization: "FGV",
        company: "",
        branch: "Sabah",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVPMSM02A201185D97600",
        assetsNumber: "PC5340008967",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVLFSM00A0321344C7600",
        assetsNumber: "PC43521",
        organization: "FGV",
        company: "",
        branch: "Johor",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVPMSM02A20701ED07600",
        assetsNumber: "PC5340009189",
        organization: "FGV",
        company: "",
        branch: "Johor",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "UNB0WSM040430030",
        assetsNumber: "FLD5340012635",
        organization: "FELDA",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVPMSM02A201186917600",
        assetsNumber: "PC5340008963",
        organization: "FGV",
        company: "",
        branch: "Johor",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVPMSM02A201185A17600",
        assetsNumber: "PC5340008946",
        organization: "FGV",
        company: "",
        branch: "Johor",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVPMSM02A2160813B7600",
        assetsNumber: "PC534001175",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVPMSM01F126164007600",
        assetsNumber: "FGV5340008117",
        organization: "FGV",
        company: "",
        branch: "Johor",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVLFSM00A032134517600",
        assetsNumber: "PC45324",
        organization: "FGV",
        company: "",
        branch: "Johor",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVVESM00Y34202BDB7600",
        assetsNumber: "FGV5340011802",
        organization: "FGV",
        company: "",
        branch: "Johor",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVPMSM02A201186637600",
        assetsNumber: "EtonComell",
        organization: "FGV",
        company: "",
        branch: "Johor",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVVESM00Y337197CB7600",
        assetsNumber: "FGV5340011751",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVLFSM00A0100308A7600",
        assetsNumber: "LPC200615121",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "DTVWUSM001225009D23000",
        assetsNumber: "FGV5210234913",
        organization: "FGV",
        company: "",
        branch: "Sabah",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "DTVY3SM00A40100E589600",
        assetsNumber: "FGV5210237434",
        organization: "FGV",
        company: "",
        branch: "Sabah",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "DTVWNSM00V316004729600",
        assetsNumber: "FLD5210236252",
        organization: "FGV",
        company: "",
        branch: "Sabah",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "DTVWUSM001225009463000",
        assetsNumber: "FGV5210234919",
        organization: "FGV",
        company: "",
        branch: "Sabah",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVGTSM007921057EC7200",
        assetsNumber: "PC5340005898",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "NXVVESM00Y350046AE7600",
        assetsNumber: "FGV5340012047",
        organization: "FGV",
        company: "",
        branch: "HQ",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "DTVWNSM01Y338025D79600",
        assetsNumber: "FGV5210237057",
        organization: "FGV",
        company: "",
        branch: "Sabah",
        assetType: "Laptop",
        status: "Active"
      },
      {
        serialNumber: "DTVWNSM01Y3380254D9600",
        assetsNumber: "FGV5210237050",
        organization: "FGV",
        company: "",
        branch: "Sabah",
        assetType: "Laptop",
        status: "Active"
      }
    ],
    "totalItems": 57,
    "totalPages": 2
  };