export const auditLogsData = {
    logs: [
      {
        type: "Authentication",
        details: "Login Successful",
        performedBy: "Admin",
        organization: "FGV",
        department: "IT",
        date: "24-Sept-2024"
      },
      {
        type: "Add Asset",
        details: "Asset G2234455",
        performedBy: "Admin",
        organization: "FGV",
        department: "IT",
        date: "21-Sept-2024"
      },
      {
        type: "Lock Asset",
        details: "Asset G449952",
        performedBy: "Ahmad",
        organization: "FGV",
        department: "IT",
        date: "20-Sept-2024"
      }
    ],
    totalItems: 3,
    totalPages: 1
  };