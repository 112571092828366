import React, { useState } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { Typography, Button, TextField, Select, MenuItem, Grid } from '@mui/material';
import { requestListData } from './staticRequestListData';

const AddNewRequestRoot = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const FormContainer = styled.div`
  background-color: #1A1D1F;
  padding: 24px;
  border-radius: 8px;
  width: 80%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
`;

const FormTitle = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
  color: #fff;
`;

const StyledSelect = muiStyled(Select)`
  width: 100%;
  background-color: #111315;
  color: #fff;
  .MuiSelect-icon {
    color: #fff;
  }
`;

const StyledTextField = muiStyled(TextField)`
  width: 100%;
  .MuiInputBase-root {
    color: #fff;
    background-color: #111315;
  }
  .MuiInputLabel-root {
    color: #fff;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #2A2D2F;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
`;

const SubmitButton = styled(Button)`
  background-color: #FF7E00;
  color: #fff;
  &:hover {
    background-color: #E67100;
  }
`;

const CancelButton = styled(Button)`
  background-color: #555;
  color: #fff;
  &:hover {
    background-color: #777;
  }
`;

const AddNewRequest = ({ onClose }) => {
  const [formData, setFormData] = useState({
    organization: '',
    branch: '',
    category: '',
    assetType: '',
    brand: '',
    requestDate: '',
    remarks: '',
    supportingDocument: '',
    requestorName: '',
    requestorDepartment: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    console.log('Form submitted:', formData);
    onClose();
  };

  return (
    <AddNewRequestRoot>
      <FormContainer>
        <FormTitle>Add New Request</FormTitle>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
          <StyledSelect
              name="organization"
              value={formData.organization}
              onChange={handleChange}
              displayEmpty
              renderValue={(selected) => selected || "Please Select Organization"}
            >
              {requestListData.formData.organization.map((org) => (
                <MenuItem key={org} value={org}>{org}</MenuItem>
              ))}
            </StyledSelect>
          </Grid>
          <Grid item xs={12} md={6}>
          <StyledSelect
              name="branch"
              value={formData.branch}
              onChange={handleChange}
              displayEmpty
              renderValue={(selected) => selected || "Please Select Branch"}
            >
              {requestListData.formData.branch.map((branch) => (
                <MenuItem key={branch} value={branch}>{branch}</MenuItem>
              ))}
            </StyledSelect>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledSelect
              name="category"
              value={formData.category}
              onChange={handleChange}
              displayEmpty
              renderValue={(selected) => selected || "Please Select Category"}
            >
              {requestListData.formData.categories.map((category) => (
                <MenuItem key={category} value={category}>{category}</MenuItem>
              ))}
            </StyledSelect>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledSelect
              name="assetType"
              value={formData.assetType}
              onChange={handleChange}
              displayEmpty
              renderValue={(selected) => selected || "Please Select Assets"}
            >
              {requestListData.formData.assetTypes.map((assetType) => (
                <MenuItem key={assetType} value={assetType}>{assetType}</MenuItem>
              ))}
            </StyledSelect>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledSelect
              name="brand"
              value={formData.brand}
              onChange={handleChange}
              displayEmpty
              renderValue={(selected) => selected || "Please Select Assets Brand"}
            >
              {requestListData.formData.brands.map((brand) => (
                <MenuItem key={brand} value={brand}>{brand}</MenuItem>
              ))}
            </StyledSelect>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTextField
              name="requestDate"
              type="date"
              label="Request Date"
              value={formData.requestDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              name="remarks"
              multiline
              rows={4}
              label="Remarks"
              value={formData.remarks}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              name="supportingDocument"
              type="file"
              label="Supporting Document"
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTextField
              name="requestorName"
              label="Requestor Name"
              value={formData.requestorName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
          <StyledSelect
              name="requestorDepartment"
              value={formData.requestorDepartment}
              onChange={handleChange}
              displayEmpty
              renderValue={(selected) => selected || "Please Select Department"}
            >
              {requestListData.formData.departments.map((department) => (
                <MenuItem key={department} value={department}>{department}</MenuItem>
              ))}
            </StyledSelect>
          </Grid>
        </Grid>
        <ButtonContainer>
          <CancelButton variant="contained" onClick={onClose}>Cancel</CancelButton>
          <SubmitButton variant="contained" onClick={handleSubmit}>Submit</SubmitButton>
        </ButtonContainer>
      </FormContainer>
    </AddNewRequestRoot>
  );
};

export default AddNewRequest;