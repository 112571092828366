import React, { useState } from 'react';
import styled from 'styled-components';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Menu, MenuItem, Select, Typography } from '@mui/material';
import MoreHoriz from '@mui/icons-material/MoreVert';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import UserProfileSection from './UserProfileSection';

const SectionContainer = styled.div`
  background-color: #1A1D1F;
  padding: 24px;
  border-radius: 8px;
  margin-top: 24px;
`;

const StyledTableContainer = styled(TableContainer)`
  background-color: #1A1D1F;
`;

const StyledTableCell = styled(TableCell)`
  color: #fff;
  border-bottom: 1px solid #2A2D2F;
`;

const StyledTableRow = styled(TableRow)`
  &:last-child td, &:last-child th {
    border-bottom: 0;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

const StyledSelect = styled(Select)`
  color: white;
  &:before {
    border-color: white;
  }
  &:after {
    border-color: white;
  }
  .MuiSelect-icon {
    color: white;
  }
`;

const UserNameLink = styled.a`
  color: #FF7E00;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const UserProfilePopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const UserListSection = ({ users, totalUsers, totalPages }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [showUserProfile, setShowUserProfile] = useState(false);

  const handleMenuOpen = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUser(null);
  };

  const handleAction = (action) => {
    console.log(`${action} user:`, selectedUser);
    handleMenuClose();
  };

  const handleProfileOpen = (user) => {
    setSelectedUser(user);
    setShowUserProfile(true);
  };

  const handleProfileClose = () => {
    setShowUserProfile(false);
    setSelectedUser(null);
  };

  return (
    <SectionContainer>
      <StyledTableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{ fontWeight: 'bold' }}>User</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }}>User Email</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }}>Role</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }}>Status</StyledTableCell>
              <StyledTableCell sx={{ fontWeight: 'bold' }}>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <StyledTableRow key={user.email}>
                <StyledTableCell>
                  <UserNameLink onClick={() => handleProfileOpen(user)}>
                    {user.name}
                  </UserNameLink>
                </StyledTableCell>
                <StyledTableCell>{user.email}</StyledTableCell>
                <StyledTableCell>{user.role}</StyledTableCell>
                <StyledTableCell>{user.status}</StyledTableCell>
                <StyledTableCell>
                  <IconButton sx={{ color: 'white' }} onClick={(e) => handleMenuOpen(e, user)}>
                    <MoreHoriz />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <Pagination>
        <div>
          Items per page:
          <StyledSelect
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(e.target.value)}
          >
            <MenuItem value={10} selected>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </StyledSelect>
          <Typography component="span" sx={{ ml: 2 }}>
            {`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(currentPage * itemsPerPage, totalUsers)} of ${totalUsers} items`}
          </Typography>
        </div>
        <div>
          <Typography component="span" sx={{ mr: 2 }}>
            {`${currentPage} of ${totalPages} pages`}
          </Typography>
          <IconButton 
            onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
            disabled={currentPage === 1}
          >
            <ChevronLeftIcon />
          </IconButton>
          <IconButton 
            onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
            disabled={currentPage === totalPages}
          >
            <ChevronRightIcon />
          </IconButton>
        </div>
      </Pagination>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleAction('Edit')}>Edit</MenuItem>
        <MenuItem onClick={() => handleAction('Reset Password')}>Reset Password</MenuItem>
        <MenuItem onClick={() => handleAction('Block')}>Block</MenuItem>
        <MenuItem onClick={() => handleAction('Delete')}>Delete</MenuItem>
        <MenuItem onClick={() => handleAction('Suspend')}>Suspend</MenuItem>
      </Menu>

      {showUserProfile && (
        <UserProfilePopup>
          <UserProfileSection user={selectedUser} onClose={handleProfileClose} />
        </UserProfilePopup>
      )}
    </SectionContainer>
  );
};

export default UserListSection;