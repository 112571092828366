import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import Assets from './components/Assets';
import Reports from './components/Reports';
import RequestList from './components/RequestList';
import AuditLogs from './components/AuditLogs';
import Settings from './components/Settings';
import LaptopDetailsAssets from './components/LaptopDetailsAssets';

function AppContent() {

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Login />} />
      <Route path="/home" element={<Home />} />
      <Route path="/assets" element={<Assets />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/request-list" element={<RequestList />} />
      <Route path="/audit-logs" element={<AuditLogs />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/assets/:assetNumber" element={<LaptopDetailsAssets />} />
    </Routes>
  );
}

function App() {
  return (
    <Router>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Login />} />
      <Route path="/home" element={<Home />} />
      <Route path="/assets" element={<Assets />} />
      <Route path="/assets/:assetNumber" element={<LaptopDetailsAssets />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/request-list" element={<RequestList />} />
      <Route path="/audit-logs" element={<AuditLogs />} />
      <Route path="/settings" element={<Settings />} />
      {/* Add other routes here */}
    </Routes>
  </Router>
  );
}

export default App;